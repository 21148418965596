import React from 'react'
import { showLoading } from 'react-global-loading';
import Listbox from "react-widgets/Listbox";

import { AppContext } from '../../../AppContext';
import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";
import { localTimeFormat } from '../../../utilities/timeHelper'
import fetchConfigsPerUnit from "../../../calls/administration/configs/fetchConfigsPerUnit";
import { deleteConfig, pushConfig } from "../../../calls/administration/configs/createConfig";
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors'
import ConfirmBox from '../ConfirmBox';
import ConfigCreation from './ConfigCreation';

const types = ["system", "user"];

function UnitConfigsTable(props) {
    const { setGlobalError } = React.useContext(AppContext);
    const [error, setError] = React.useState("");
    const [configs, setConfigs] = React.useState([]);
    const [configCreationOpen, setConfigCreationOpen] = React.useState(false);
    const [selectedConfig, setSelectedConfig] = React.useState(null);
    const [configDeleteData, setConfigDeleteData] = React.useState(null);
    const [configEditData, setConfigEditData] = React.useState(null);
    const [configPushData, setConfigPushData] = React.useState(null);

    const updateTable = (onResult) => {
        showLoading(true)
        fetchConfigsPerUnit((e) => {
            showLoading(false)
            if (e.error) {
                setGlobalError(e.error)
                if (onResult) onResult(false);
                return;
            }

            setConfigs(e.data)
            if (onResult) onResult(true);
        }, props.unit.id);
    }

    React.useEffect(() => {
        if (props.open) {
            if (props.unit) {
                updateTable();
            }
        }
        return () => {
            setConfigEditData(null)
        }
    }, [props.open]);
    const title = `Unit Configuration Table (${props.unit?.hwSerial})`;
    const editableConfig = selectedConfig?.active;
    return <>
        <dialog open={props.open}
            style={{
                top: "50%",
                bottom: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "650px", height: "450px"
            }}>
            <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }} >
                <div style={{ textAlign: "left", display: "flex" }}>
                    <span style={{ width: "100%" }}>{title}</span>
                    <button onClick={() => {
                        if (!props?.onClose) return;
                        props?.onClose()
                    }}>x</button>
                </div>
                <hr />
                <div style={{ height: "100%", width: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}
                    onClick={() => {
                        setSelectedConfig(null)
                    }}>
                    <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                        <table style={{ width: "100%", fontSize: "14px" }} onClick={(e) => {
                            e.stopPropagation();
                        }}>

                            <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                <tr>
                                    <th style={{ textAlign: "left", }}>Type</th>
                                    <th style={{ textAlign: "left", }}>Feature</th>
                                    <th style={{ textAlign: "left" }}>Config</th>
                                    <th style={{ textAlign: "left" }}>Value</th>
                                    {/* <th style={{ textAlign: "left" }}>Ip</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    configs &&
                                    configs.map((config, index) => {
                                        const finalConfig = config;
                                        const isSelected = (selectedConfig && finalConfig.id === selectedConfig?.id);
                                        return (
                                            <tr key={index}
                                                style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                                onClick={() => {
                                                    setSelectedConfig(finalConfig)
                                                }}>
                                                <td style={{ textAlign: "left" }}>{types[finalConfig.type]}</td>
                                                <td style={{ textAlign: "left" }}>{finalConfig.featureIndex}</td>
                                                <td style={{ textAlign: "left" }}>{finalConfig.configIndex}</td>
                                                <td style={{ textAlign: "left" }}>{finalConfig.value}</td>
                                                {/* <td style={{ textAlign: "left" }}>{finalActivity.ipAddress}</td> */}
                                            </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr />
                <div style={{ display: "flex" }} >
                    <button style={{ width: "100px", marginRight: "5px" }} onClick={() => {
                        setConfigCreationOpen(true)
                    }}>Add config</button>
                    <span style={{ flex: 1 }}></span>
                    {
                        //editableConfig &&
                        <button style={{ width: "100px", marginRight: "5px" }} disabled={!editableConfig} onClick={() => {
                            setConfigEditData({
                                config: selectedConfig
                            });
                            setConfigCreationOpen(true)
                        }}>Edit config</button>
                    }
                    <button style={{ width: "100px", marginRight: "5px" }} disabled={!selectedConfig} onClick={() => {
                        const cfg = selectedConfig;
                        if (!cfg) return;
                        setConfigPushData({
                            open: true,
                            title: "Confirm Config Push Action",
                            message: `You are about to push config < ${types[cfg.type]}, ${cfg.featureIndex}, ${cfg.configIndex}, ${cfg.value} >`,
                            config: cfg
                        });
                    }}>
                        Push config
                    </button>
                    {
                        //editableConfig &&
                        <button style={{ width: "100px" }} disabled={!editableConfig}
                            onClick={() => {
                                const cfg = selectedConfig;
                                setConfigDeleteData({
                                    open: true,
                                    title: "Confirm Delete Action",
                                    message: `You are about to delete config < ${types[cfg.type]}, ${cfg.featureIndex}, ${cfg.configIndex}, ${cfg.value} >`,
                                    config: selectedConfig
                                });
                            }}>Delete config</button>
                    }
                </div>
                {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                    {error}
                </div>)}
            </div>
        </dialog>
        {
            configCreationOpen && <div style={{ display: "flex", height: "100%", width: "100%", position: "relative", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4) }}>
                <ConfigCreation
                    ondispose={() => {
                        setConfigEditData(null)
                    }}
                    editData={configEditData}
                    onCancel={() => {
                        setConfigCreationOpen(false)
                    }}
                    onConfigCreated={() => {
                        setConfigCreationOpen(false)
                        updateTable();
                    }}
                    onConfigEdited={() => {
                        setConfigCreationOpen(false)
                        updateTable();
                    }}

                    relatedUnit={props.unit}
                    open={configCreationOpen} />
            </div>
        }
        <ConfirmBox
            onCancel={() => {
                setConfigDeleteData(null)
            }}
            onConfirm={(config) => {
                const data = {
                    configId: config.id,
                };

                showLoading(true)
                deleteConfig(data, () => {
                    setConfigDeleteData(null)
                    showLoading(false)
                    updateTable();
                });
            }}
            title={configDeleteData?.title}
            data={configDeleteData?.config}
            open={configDeleteData?.open}
            message={configDeleteData?.message} />
        <ConfirmBox
            onCancel={() => {
                setConfigPushData(null)
            }}
            onConfirm={(config) => {
                const data = {
                    configId: config.id,
                };

                showLoading(true)
                pushConfig(data, () => {
                    setConfigPushData(null)
                    showLoading(false)
                });
            }}
            title={configPushData?.title}
            data={configPushData?.config}
            open={configPushData?.open}
            message={configPushData?.message} />
    </>
}



export default UnitConfigsTable;