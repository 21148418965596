import React from 'react'
import { useEffect, useContext } from 'react';
import { AppContext } from "../AppContext";
import { showLoading } from 'react-global-loading';
import { useNavigate } from "react-router-dom";
import logout from '../calls/authentication/logout';
import { setConfigProperty } from "../configurations/appConfig"
function Logout(props) {
    const { setAuthorisationToken } = React.useContext(AppContext);
    const { setActiveUser } = React.useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
        showLoading(true)
        logout(setActiveUser,
            () => {
                setAuthorisationToken("");
                setConfigProperty("authorisationToken", "");
                setActiveUser(null);
                showLoading(false)
                navigate("/home", { replace: true })
            });

    }, []);
    return null;
}

export default Logout;