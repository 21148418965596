import './App.css';
import React from 'react'
import { loadAppConfigs } from './configurations/appConfig';
import { useState, useEffect, useCallback } from 'react';
import { GlobalLoading, showLoading } from 'react-global-loading';
import WebtraceDashboard from './components/Dashboard/WebtraceDashboard'
import WebTraceDemoDashboard from './components/DashboardDemo/WebTraceDemoDashboard'
import Administration from './components/administration/Administration'
import Home from './components/Home/Home';
import PageSelector from './components/PageSelector';
import Logout from './components/Logout';
import { AppContext } from "./AppContext";
import { loadLanguage } from './language/lang';

import { BrowserView, MobileView, isMobile, isIOS, isAndroid } from 'react-device-detect';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import hello from './calls/authentication/hello';
import fetchVisitorCoordinates from './calls/identity/fetchVisitorCoordinates';
import { isDevelopment } from './utilities/devHelper';
import AboutBoxComponent from './components/dialogs/AboutBoxComponent';
import { CookiesProvider } from "react-cookie";
import refreshTokenFromContext from './calls/authentication/refreshTokenFromContext'
import getUserProfile from './calls/authentication/getUserProfile'

import { setConfigProperty } from './configurations/appConfig'
import PasswordChange from './components/PasswordChange/PasswordChange'

import GlobalError from "./components/menus/GlobalError"


window.onerror = (...args) => {
    if (args[0] === 'ResizeObserver loop limit exceeded') {
        return true;
    } else {
        return false;
    }
};

function App() {
    const [timerReady, setTimerReady] = useState(false);
    const [styleReady, setStyleReady] = useState(false);
    const [tokenReady, setTokenReady] = useState(false);
    const [configLoaded, setConfigLoaded] = useState(false);
    const [visitorReady, setVisitorReady] = useState(false);
    const [googleApiKey, setGoogleApiKey] = useState(null);
    const [ready, setReady] = useState(false);

    const fullScreenHandle = useFullScreenHandle();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [busy, setBusy] = useState(true);
    const [map, setMap] = useState(null);
    const [online, setOnline] = useState(true);
    const [portrait, setPortrait] = useState(false);
    const [accountBarData, setAccountBarData] = useState({ expanded: true });
    const [gridBarData, setGridBarData] = useState({ expanded: false });
    const [makerSelectedId, setMakerSelectedId] = useState(-1);
    const [gridSelectedRowId, setGridSelectedRowId] = useState(-1);
    const [gridScrollIndex, setGridScrollIndex] = useState(-1);
    const [selectionWarning, setSelectionWarning] = useState(false);
    const [contactsPanel, setContactsPanel] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [authorisationToken, setAuthorisationToken] = useState("");
    const [settingActive, setSettingActive] = useState(false);
    const [accountData, setAccountData] = useState({});

    const [visitorLocation, setVisitorLocation] = useState(null);
    const [settings, setSettings] = useState(null);

    const [activeUser, setActiveUser] = useState(null);
    const [activeRoles, setActiveRoles] = useState(null);
    const [showAbout, setShowAbout] = useState(false);
    const [aboutContactInfo, setAboutContactInfo] = React.useState(null);
    const [globalError, setGlobalError] = React.useState(null);
    const [timerTick, setTimerTick] = useState(false);
    const [timerCount, setTimerCount] = useState(false);

    const [appThemeColor, setAppThemeColor] = React.useState("#6f7a7e"); //ios  **

    const onResize = () => {
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;
        if (innerHeight > innerWidth) {
            setPortrait(true);
        }
        else {
            setPortrait(false);
        }
    };

    const onOnlineStatus = () => {
        setOnline(navigator.onLine);
    };

    const refreshVisitorInformation = () => {
        fetchVisitorCoordinates((e) => {
            if (e.error) {
                setVisitorReady(true)
                return;
            }

            setVisitorLocation(e.data);
            setVisitorReady(true)
        })
    }

    const loadConfiguations = () => {
        loadAppConfigs((data) => {
            setSettings(data);
            setConfigLoaded(true);
            refreshVisitorInformation();
            updateToken();
            if (!isDevelopment()) {

                hello((e) => {
                    if (e.error) {
                        return;
                    }

                    console.info("Hello");
                });
            }
        });
    }

    const ImportStyles = () => {
        if (!isMobile) {
            import("./styles/widgetStyle.css")
                .then(() => {
                    setStyleReady(true);
                })
                .catch(() => {

                });
        }
        else {
            import("./styles/mobile/widgetStyle.css")
                .then(() => {
                    setStyleReady(true);
                })
                .catch(() => {

                });
        }
    };

    const updateToken = () => {
        refreshTokenFromContext((e) => {
            console.log(e)
            if (e.error) {
                setTokenReady(true)
                return;
            }

            const token = e.data.accessToken
            setAuthorisationToken(token)
            setConfigProperty("authorisationToken", token);
            getUserProfile((e) => {
                console.log(e)
                if (e.error) {
                    setTokenReady(true)
                    return;
                }

                const user = e.data.user;
                const roles = e.data.roles;

                setActiveUser(user);
                const normalisedRoles = roles.map(element => {
                    return element.toLowerCase();
                });

                setActiveRoles(normalisedRoles);
                setTokenReady(true)
            })
        })
    }

    const ConfigureTheme = () => {
        if (isMobile && isAndroid) {
            setAppThemeColor("#4A148C")
        }

        else if (isMobile && isIOS) {
            setAppThemeColor("#01579B")
        }
        else {
            setAppThemeColor("#004D40")
        }
    };

    const updateTimerTick = (timer) => {
        setTimerTick(false)
        if (!timerReady)
            if (styleReady, configLoaded, tokenReady, visitorReady)
                setTimerReady(true)

        const newCount = timerCount + 1;
        setTimerCount(newCount);
        //console.log("Timer count ==> " + newCount)

    }

    const onFullScreenChanged = useCallback((state, handle) => {
        setIsFullScreen(state);
    }, [fullScreenHandle]);

    useEffect(() => {
        showLoading(true);
        onResize();
        onOnlineStatus();
        loadLanguage("french");
        ImportStyles();
        loadConfiguations();
        ConfigureTheme();
        window.addEventListener('resize', onResize);
        window.addEventListener("online", onOnlineStatus);
        window.addEventListener("offline", onOnlineStatus);
        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener("online", onOnlineStatus);
            window.removeEventListener("offline", onOnlineStatus);
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimerTick(timer)
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        if (timerTick) {
            updateTimerTick(timerTick)
        }
    }, [timerTick]);

    useEffect(() => {
        if (timerReady && styleReady && configLoaded && tokenReady && visitorReady)
            setReady(true)

    }, [timerReady, styleReady, configLoaded, tokenReady, visitorReady]);

    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChanged} >
            <BrowserRouter>
                <AppContext.Provider value={
                    {
                        busy: busy, setBusy: setBusy,
                        map: map, setMap: setMap,
                        online: online, setOnline: setOnline,
                        portrait: portrait, setPortrait: setPortrait,
                        accountBarData: accountBarData, setAccountBarData: setAccountBarData,
                        gridBarData: gridBarData, setGridBarData: setGridBarData,
                        fullScreenHandle: fullScreenHandle,
                        selectionWarning, setSelectionWarning,
                        makerSelectedId, setMakerSelectedId,
                        gridSelectedRowId, setGridSelectedRowId,
                        gridScrollIndex, setGridScrollIndex,
                        contactsPanel, setContactsPanel,
                        loginOpen, setLoginOpen,
                        loggedIn, setLoggedIn,
                        authorisationToken, setAuthorisationToken,
                        settingActive, setSettingActive,
                        accountData, setAccountData,
                        googleApiKey, setGoogleApiKey,
                        settings, setSettings,
                        activeUser, setActiveUser,
                        activeRoles, setActiveRoles,
                        showAbout, setShowAbout,
                        aboutContactInfo, setAboutContactInfo,
                        isFullScreen, setIsFullScreen,
                        visitorLocation, setVisitorLocation,
                        appThemeColor, setAppThemeColor,
                        globalError, setGlobalError
                    }}>
                    <BrowserView>
                        <CookiesProvider>
                            <div className="App" onContextMenu={(e) => {
                                e.preventDefault(); // prevent the default behaviour when right clicked
                                console.log("Right Click");
                            }}>
                                <ApplicationOverlay />
                                {ready ? <Routes>
                                    <Route path="/" element={<Home ready={ready} />} />
                                    <Route path="/dashboard" element={<WebtraceDashboard ready={ready} />} />
                                    <Route path="/selector" element={<PageSelector ready={ready} />} />
                                    <Route path="/demo" element={<WebTraceDemoDashboard ready={ready} />} />
                                    <Route path="/administration" element={<Administration ready={ready} />} />
                                    <Route path="/logout" element={<Logout ready={ready} />} />
                                    <Route path="/home" element={<Home ready={ready} />} />
                                    <Route path="/password_change" element={<PasswordChange cancelRoute='/home' ready={ready} />} />
                                </Routes> : null}
                                <GlobalLoading loadingSize={35} loadingType='spokes'
                                    loadingBackgroundColor='rgba(255, 255, 255, 0.5)'
                                    loadingColor="#eee"
                                    backgroundColor='rgba(0, 0, 0, 0.5)'>
                                    {/*<ReactLoading type="spin"/>*/}
                                </GlobalLoading>
                            </div>
                        </CookiesProvider>
                    </BrowserView>
                    <MobileView>
                        <CookiesProvider>
                            <div className="App" onContextMenu={(e) => {
                                e.preventDefault(); // prevent the default behaviour when right clicked
                                console.log("Right Click");
                            }}>
                                <ApplicationOverlay />
                                {ready ? <Routes>
                                    <Route path="/" element={<Home ready={ready} />} />
                                    <Route path="/dashboard" element={<WebtraceDashboard ready={ready} />} />
                                    <Route path="/selector" element={<PageSelector ready={ready} />} />
                                    <Route path="/demo" element={<WebTraceDemoDashboard ready={ready} />} />
                                    <Route path="/administration" element={<Administration ready={ready} />} />
                                    <Route path="/logout" element={<Logout ready={ready} />} />
                                    {/* <Route path="*" element={<Home ready={ready} />} /> */}
                                </Routes> : null}
                                <GlobalLoading loadingSize={25} loadingType='spokes'
                                    loadingBackgroundColor='rgba(255, 255, 255, 0.5)'
                                    loadingColor="#eee"
                                    backgroundColor='rgba(0, 0, 0, 0.5)'>
                                    {/*<ReactLoading type="spin"/>*/}
                                </GlobalLoading>
                            </div>
                        </CookiesProvider>
                    </MobileView>
                </AppContext.Provider>
            </BrowserRouter>
        </FullScreen>);
};

function ApplicationOverlay() {
    const { showAbout, setShowAbout } = React.useContext(AppContext);
    const { globalError, setGlobalError } = React.useContext(AppContext);

    return (
        <>
            {
                showAbout ?
                    <AboutBoxComponent
                        open={showAbout}
                        onClickOutside={() => { setShowAbout(false) }}
                        onClose={() => { setShowAbout(false) }} /> :
                    null
            }
            {
                globalError &&
                <div style={{ display: "flex", position: "absolute", right: "20px", top: "20px", zIndex: 1 }}>
                    <GlobalError
                        error={globalError}
                        onComplete={() => {
                            setGlobalError(null)
                        }}>
                    </GlobalError>
                </div>
            }
        </>);
}

export default App;
