import React from 'react'
import { useEffect, useContext } from 'react';
import dateFormat from "dateformat";

import GoogleMapComponent from '../GoogleMapComponent';
import TimeFrameComponent from '../TimeFrameComponent';
import EventsGridComponent from '../EventsGridComponent';
import UserPanelComponent from '../UserPanelComponent';
import TopBarComponenent from '../TopBarComponenent';
import NoInternet from '../dialogs/NoInternet';
import AccountBar from '../toolbars/AccountBar';
import GridBar from '../toolbars/GridBar';

import fetchDemoLastPositions from '../../calls/positions/fetchDemoLastPositions';
import fetchDemoPositions from '../../calls/positions/fetchDemoPositions';
import fetchDemoAccount from '../../calls/demo/fetchDemoAccount';
import isLogin from '../../calls/authentication/isLogin';
import fetchDemoAssets from '../../calls/demo/fetchDemoAssets'
import fetchRequestCurrentPosition from '../../calls/positions/fetchRequestCurrentPosition'
import fetchDemoGoogleApiKey from '../../calls/identity/fetchDemoGoogleApiKey';
import { buildAssetColorIconUrl } from '../../utilities/assetHelper'

import { convertTimeSent, toLocalTime } from '../../utilities/timeHelper'
import { getGoogleMapLocationsCenter } from '../../utilities/googleMapHelper'
import { AppContext } from "../../AppContext";
import { showLoading } from 'react-global-loading';
import { lang } from '../../language/lang';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import TimeDisplay from '../TimeDisplay/TimeDisplay';
import UpdaterDialog from '../dialogs/UpdaterDialog';
import FtpReportDialog from '../ftp/FtpReportDialog';
import Notificator from '../menus/Notificator';
import { useSearchParams } from 'react-router-dom';
import './WebTraceDemoDashboard.css'

function WebTraceDemoDashboard(props) {
    const navigate = useNavigate();
    const { googleApiKey, setGoogleApiKey } = React.useContext(AppContext);
    const { busy, setBusy } = useContext(AppContext);
    const { setGlobalError } = React.useContext(AppContext);
    const { accountBarData, setAccountBarData } = React.useContext(AppContext);
    const { gridBarData } = React.useContext(AppContext);
    const { setSelectionWarning } = React.useContext(AppContext);
    const { setGridSelectedRowId } = React.useContext(AppContext);
    const { isFullScreen } = React.useContext(AppContext);
    const { activeUser } = React.useContext(AppContext);
    const { appThemeColor } = useContext(AppContext);
    const [zoom, setZoom] = React.useState(15);
    const [mapData, setMapData] = React.useState({});
    const [eventsGridData, setEventsGridData] = React.useState([]);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [selectedAccount, setSelectedAccount] = React.useState(null);
    const [userActionResizeData, setUserActionResizeData] = React.useState({ width: 250 });
    const [gridResizeData, setGridResizeData] = React.useState({ height: 150 });
    const [activeAccounts, setActiveAccounts] = React.useState([]);
    const [activeAssets, setActiveAssets] = React.useState([]);
    const [ready, setReady] = React.useState(false);
    const [setShowAbout] = React.useState(false);
    const [updaterData, setUpdaterData] = React.useState(null);
    const [ftpReportData, setFtpReportData] = React.useState(null);
    const midlleContainerDivRef = React.useRef(null);
    const eventGridRef = React.useRef(null);
    const topBarDivRef = React.useRef(null);
    const bottomBarDivRef = React.useRef(null);
    const AssetDivRef = React.useRef(null);
    const AccountDivRef = React.useRef(null);
    const notificatorRef = React.useRef(null);
    const dataGridEventsGridRef = React.useRef(null);
    const googleMapCompRef = React.useRef(null);
    const timeFramePanelRef = React.useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const ensureGoogleLoaded = () => {
        return new Promise(waitForGoogle);
        function waitForGoogle(resolve, reject) {
            if (window.google && window.google.maps) {
                resolve(window.google.maps);
            }
            else {
                if (navigator.geolocation) {
                    navigator.geolocation.watchPosition(function (position) {
                        console.log('latitude >>>>>>>>', position.coords.latitude);
                        console.log('longitude >>>>>>>>', position.coords.longitude);
                    });
                }
                setTimeout(waitForGoogle.bind(this, resolve, reject), 100);
            }
        }
    }

    const retrieveLastPositions = (assetId) => {
        setBusy(true);
        showLoading(true);
        const filter = {
            assetId: assetId
        };

        fetchDemoLastPositions((e) => {
            showLoading(false);
            setBusy(false);

            if (e.error) {
                setGlobalError(e.error)
                return;
            }

            console.log(e.data);
            const userError = e.data.userError;
            if (userError) {
                let m = { ...mapData };
                m.userError = userError;
                m.markers = [];
                m.pathCoordinates = [];
                setMapData(m);
                setEventsGridData([]);
                return;
            }


            const p = e.data.position;
            const ev = e.data.event;
            const asset = e.data.asset;
            var time = toLocalTime(p.timeSent);

            var date = dateFormat(time, "dd mmm yyyy");
            var hour = dateFormat(time, "HH:MM:ss");

            const gridEvent = {
                key: p.id,
                date: date,
                hour: hour,
                asset: asset.name,
                assetType: asset.assetType,
                assetTypeIconColor: asset?.assetTypeIconColor,
                event: ev?.description,
                heading: p.heading,
                speed: p.speed,
                location: p.locationText,
                state: p.speed > 0 ? lang.language.moving : lang.language.stationary,
                timesent: convertTimeSent(p.timeSent),
                timeIndex: time.getTime(),
                idx: 1,
                data: e.data,
                id: p.id,
                assetData: asset
            };

            const marker = {
                //key: p.id,
                id: p.id,
                assetData: asset,
                positionData: p,
                eventData: ev,
                position: { lat: e.data.position.latitude, lng: e.data.position.longitude },
                icon: {
                    //url: assetProvider.getPreloadedAssetType(asset.id),
                    url: buildAssetColorIconUrl(asset.assetType, asset.assetTypeIconColor),
                    size: new window.google.maps.Size(25, 25),
                    origin: new window.google.maps.Point(0, 0),
                    scaledSize: new window.google.maps.Size(25, 25)
                },
                title: `${p.locationText}\n${date}, ${hour}\n${p.latitude}, ${p.longitude}`,
                onClick: () => {
                    const map = googleMapCompRef.current.getInternalMap();
                    //setMakerSelectedId(p.id);
                    googleMapCompRef.current.setMarkerSelection({
                        id: p.id
                    });
                    setGridSelectedRowId(p.id);
                    dataGridEventsGridRef.current.scrollToRow(gridEvent?.idx ?? 0);
                    //setGridScrollIndex(gridEvent.idx);
                    if (map) {
                        map.panTo(marker.position);
                    }
                }
            };

            const coordinate = { lat: p.latitude, lng: p.longitude, id: p.id };
            const positionCenter = { lat: e.data.position.latitude, lng: e.data.position.longitude };

            setEventsGridData([gridEvent]);
            setMapData({
                center: positionCenter,
                markers: [marker],
                pathCoordinates: [coordinate],
            });
        }, filter)
    };

    const retrievePositions = (assetId, range) => {
        setBusy(true);
        showLoading(true);
        const filter = {
            assetId: assetId,
            endDate: range.end,
            startDate: range.start
        }

        fetchDemoPositions((e) => {
            showLoading(false);
            setBusy(false);
            if (e.error) {
                setGlobalError(e.error)
                return;
            }

            console.info(e.data);
            const userError = e.data.userError;
            if (userError) {
                const m = { ...mapData };
                m.userError = userError;
                m.markers = [];
                m.pathCoordinates = [];
                setEventsGridData([]);
                setMapData(m);
                return;
            }

            const positions = e.data.positions;
            const assets = e.data.assets;
            const fuelLevels = e.data.fuelLevels;
            const events = e.data.events;
            const asset = assets.length > 0 ? assets[0] : null;
            if (!asset) {
                return;
            }

            const gridEvents = [];
            const markers = [];
            const pathCoordinates = [];
            for (const p of positions) {
                var ev = events.find(x => x.id === p.eventId);
                var time = toLocalTime(p.timeSent);
                var date = dateFormat(time, "dd mmm yyyy");
                var hour = dateFormat(time, "HH:MM:ss");
                var state = p.speed > 0 ? lang.language.moving : lang.language.stationary;
                const fuel = fuelLevels?.find(x => x.positionId === p.id);
                const timeIndex = time.getTime();
                const gridEvent = {
                    key: p.id,
                    date: date,
                    hour: hour,
                    asset: asset.name,
                    event: fuel ? `${ev?.description} (${fuel.level}L)` : ev?.description,
                    heading: p.heading,
                    speed: p.speed,
                    location: p.locationText,
                    state: state,
                    assetType: asset.assetType,
                    assetTypeIconColor: asset.assetTypeIconColor,
                    timesent: convertTimeSent(p.timeSent),
                    timeIndex: timeIndex,
                    isSelected: false,
                    id: p.id,
                    assetData: asset
                };

                const marker = {
                    //key: p.id,
                    id: p.id,
                    assetData: asset,
                    positionData: p,
                    eventData: ev,
                    position: { lat: p.latitude, lng: p.longitude },
                    icon: {
                        url: buildAssetColorIconUrl(asset.assetType, asset.assetTypeIconColor),
                        size: new window.google.maps.Size(25, 25),
                        origin: new window.google.maps.Point(0, 0),
                        scaledSize: new window.google.maps.Size(25, 25)
                    },
                    title: `${p.locationText}\n${date}, ${hour}\n${p.latitude}, ${p.longitude}`,
                    onClick: () => {
                        const map = googleMapCompRef.current.getInternalMap();
                        //setMakerSelectedId(p.id);
                        googleMapCompRef.current.setMarkerSelection({
                            id: p.id
                        });
                        setGridSelectedRowId(p.id);
                        dataGridEventsGridRef.current.scrollToRow(gridEvent?.idx ?? 0);
                        //setGridScrollIndex(gridEvent?.idx ?? 0);
                        if (map) {
                            map.panTo(marker.position);
                        }
                    }
                };

                const coordinate = { lat: p.latitude, lng: p.longitude, id: p.id, timeIndex, inTrip: p.inTrip };
                gridEvents.push(gridEvent);
                markers.push(marker);
                pathCoordinates.push(coordinate);
            }

            const positionCenter = getGoogleMapLocationsCenter(markers.map(x => x.position));
            gridEvents.sort((b, a) => (a.timeIndex > b.timeIndex) ? 1 : ((b.timeIndex > a.timeIndex) ? -1 : 0))
            pathCoordinates.sort((a, b) => (a.timeIndex > b.timeIndex) ? 1 : ((b.timeIndex > a.timeIndex) ? -1 : 0))
            for (var i = 0; i < gridEvents.length; i++) {
                gridEvents[i].idx = i;
                gridEvents[i].prevNode = i > 0 ? gridEvents[i - 1] : null;
                gridEvents[i].nextNode = i < (gridEvents.length - 1) ? gridEvents[i + 1] : null;
            }

            if (markers.length <= 0) {
                const m = { ...mapData };
                m.userError = `${lang.language.eventGridNoRecord}\n(${range.description})`;
                m.markers = [];
                m.pathCoordinates = [];
                setEventsGridData([]);
                setMapData(m);
                return;
            }


            setEventsGridData(gridEvents);

            const filteredPathCoordinates = [];
            for (var i = 0; i < pathCoordinates.length; i++) {
                if (pathCoordinates[i].inTrip) {
                    filteredPathCoordinates.push(pathCoordinates[i]);
                }
            }
            setMapData({
                center: positionCenter,
                markers: markers,
                pathCoordinates: filteredPathCoordinates,
            });

            fitMapBounds(pathCoordinates);
        }, filter)
    }

    const onGo = (range) => {
        var assetId = selectedAsset?.id;
        if (!assetId) {
            const assets = activeAssets;
            if (!assets || assets.length < 1) {
                return;
            }

            const firstAsset = assets[0];
            assetId = firstAsset.id;
            setSelectedAsset(firstAsset);
        }

        setSelectionWarning(false);
        if (!range) {
            retrieveLastPositions(assetId);
            return;
        }

        retrievePositions(assetId, range);
    }

    const getBoundsWidthHeight = (bounds) => {
        var spherical = window.google.maps.geometry.spherical;
        const cor1 = bounds.getNorthEast();
        const cor2 = bounds.getSouthWest();
        const cor3 = new window.google.maps.LatLng(cor2.lat(), cor1.lng());
        const cor4 = new window.google.maps.LatLng(cor1.lat(), cor2.lng());

        const width = spherical.computeDistanceBetween(cor1, cor3);
        const height = spherical.computeDistanceBetween(cor1, cor4);
        return { w: width, h: height };
    }


    const fitMapBounds = (coordinates) => {
        const internalMap = googleMapCompRef.current.getInternalMap();
        if (!internalMap) {
            return;
        }

        if (coordinates.length === 1) {
            internalMap.panTo(coordinates[0]);
            return;
        }

        var containAll = true;
        const mapBounds = internalMap.getBounds();
        const bounds = new window.google.maps.LatLngBounds();
        for (var point of coordinates) {
            const loc = new window.google.maps.LatLng(point.lat, point.lng);
            bounds.extend(loc);
            if (!mapBounds.contains(loc)) {
                containAll = false;
            }
        }

        if (containAll) {
            return;
        }

        const mSize = getBoundsWidthHeight(mapBounds);
        const cSize = getBoundsWidthHeight(bounds);
        if (mSize.w > cSize.w && mSize.h > cSize.h) {
            internalMap.panTo(bounds.getCenter());
            return;
        }

        internalMap.fitBounds(bounds);
    }

    const onAssetSelection = (e) => {
        const assets = activeAssets;
        if (!assets || assets.length < 1) {
            return;
        }

        const assetId = e.id;
        var asset = activeAssets.find(x => x.id === assetId);
        if (!asset) {
            return;
        }

        setSelectionWarning(false);
        setSelectedAsset(asset);
        retrieveLastPositions(assetId);
    }

    const onGridRowSelection = (rowId) => {
        const map = googleMapCompRef.current.getInternalMap();
        if (!map) return;
        const markers = mapData?.markers;
        if (!markers) return;
        const marker = markers.find(x => x.id === rowId);
        const location = marker?.position;
        if (!location) return;
        map.panTo(location);
        googleMapCompRef.current.setMarkerSelection({
            id: rowId,
            activateSelection: true,
        });
    };

    const retrieveActiveAssets = (onComplete) => {
        fetchDemoAssets((e) => {
            if (e.error) { if (onComplete) onComplete(false, null) }
            var assets = [...e.data];
            setActiveAssets(assets);
            if (onComplete) onComplete(true, e.data)
        })
    }

    const retrieveDemoAccount = (onComplete) => {
        setReady(false)
        fetchDemoAccount((e) => {
            if (e.error) {
                if (onComplete) onComplete(false)
                return;
            }

            const accounts = [e.data.account];
            setActiveAccounts(accounts)
            if (accounts.length <= 0) {
                if (onComplete) onComplete(false)
                return;
            }

            const firstAccount = accounts[0];
            setSelectedAccount(firstAccount)
            retrieveActiveAssets((state, assets) => {
                if (!assets || assets.length <= 0) {
                    if (onComplete) onComplete(false)
                    return;
                }
                const firstAsset = assets[0];
                setSelectedAsset(firstAsset)
                //setReady(true)
                ensureGoogleLoaded().then(() => {
                    if (searchParams.size <= 0) {
                        retrieveLastPositions(firstAsset.id)
                    }
                    else {
                        loadQueryParameters(assets)
                    }


                    if (onComplete) onComplete(state)
                });
            });
        });
    }

    const loadQueryParameters = (assets) => {
        const assetId = searchParams.get("uid");
        if (assetId) {
            const id = parseInt(assetId);
            if (assets && assets.length > 0) {
                var asset = assets.find(x => x.id === id);
                setSelectedAsset(asset)
            }
        }
        const value = searchParams.get("idx");
        if (!value || parseInt(value) === 0) {
            retrieveLastPositions(assetId)
            return;
        }

        const startDate = searchParams.get("start");
        const endDate = searchParams.get("end");

        const precision = searchParams.get("pr");
        const expandAccountBar = searchParams.get("ab");

        if (assetId) {
            const copy = { ...accountBarData };
            copy.expanded = expandAccountBar ? true : false;
            setAccountBarData(copy);
        }

        if (assetId && startDate && endDate && value) {
            const timeConfig = {
                valueIndex: value,
                start: new Date(startDate),
                end: new Date(endDate),
                precision: precision ? true : false
            };

            timeFramePanelRef?.current?.configureTimeFrame(timeConfig);

            setSelectionWarning(false);
            const range = {
                end: timeConfig.end,
                start: timeConfig.start,
                description: "Query range"
            }

            retrievePositions(assetId, range);
        }
    }

    const retrieveGoogleApiKeys = () => {
        fetchDemoGoogleApiKey((apiResult) => {
            if (apiResult.error) {
                return;
            }

            if (!googleApiKey)
                setGoogleApiKey(apiResult.data);
        });
    }

    const checkIfReady = () => {
        showLoading(true)
        setBusy(true)
        isLogin((e) => {
            retrieveDemoAccount((e) => {
                setReady(true)
                showLoading(false)
                setBusy(false)
            });
        })
    }

    const onResize = () => {
        const newGridResizeData = { ...gridResizeData };
        newGridResizeData.height = eventGridRef?.current?.clientHeight;
        setGridResizeData(newGridResizeData);
    }

    useEffect(() => {
        checkIfReady()
        retrieveGoogleApiKeys();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "25px";
    const timeFrameHeight = mobile ? "110px" : "150px";

    const minGridHeight = mobile ? "40px" : "150px";
    const mapMinWidth = mobile ? "80px" : "200px";
    const mapMinHeight = mobile ? "80px" : "150px";

    const sideBorderWidth = mobile ? "2px" : "5px";

    const userActionWidth = mobile ?
        (accountBarData?.expanded ? "150px" : "30px") :
        (userActionResizeData.width + 'px');
    const accountPanelMinWidth = mobile ? "30px" : "150px";
    const userActionExpended = accountBarData?.expanded;
    const userActionMinHeight = 160;

    const middleDivHeight = midlleContainerDivRef?.current?.clientHeight;
    const maxEventsgridHeight = middleDivHeight ? middleDivHeight * 3 / 4 : undefined;
    const maxEventsgridHeightPx = maxEventsgridHeight ? `${maxEventsgridHeight}px` : "50%";
    const timeFrameFollowSize = eventGridRef?.current?.clientHeight ?
        `${eventGridRef?.current?.clientHeight}px` :
        `${minGridHeight}px`;


    const innerHeight = window.innerHeight;
    const gridBarExpanded = gridBarData?.expanded;

    const gridHeight = mobile ? (gridBarExpanded ? `${innerHeight / 2}px` : "80px") : (gridResizeData.height + "px");
    return (
        <div className='full-screen'>
            <div ref={topBarDivRef} style={{ width: "100%", height: barHeight }}>
                <TopBarComponenent
                    showHome={true}
                    userMenuControlOptions={{
                        userMenuControl: false,
                        showUserMenuAdministration: false,
                        showUserMenuDashboard: false
                    }}
                    showScreenControl={true}
                    showInfo={true}
                    showRefresh={false}
                    showFullQuery={false}
                    user={activeUser}
                    onAbout={() => {
                        setShowAbout(true)
                    }}
                    onShowHome={() => {
                        showLoading(true);
                        setTimeout(() => {
                            navigate("/home", { replace: true })
                        }, 1000);
                    }}
                    onShowFullQuery={() => {
                        const timeConfig = timeFramePanelRef.current.getTimeFrameConfiguration();
                        const assetId = selectedAsset?.id;

                        setSearchParams({
                            uid: assetId,
                            start: timeConfig?.start?.toJSON(),
                            end: timeConfig?.end?.toJSON(),
                            idx: timeConfig?.valueIndex,
                            pr: timeConfig.precision,
                            pop: false,
                            gr: gridBarData?.expanded
                        });

                    }} />
            </div>
            <div className='middle-frame'>
                <div className='middle-side-bar' style={{ backgroundColor: appThemeColor, width: sideBorderWidth }}>
                </div>
                <div ref={midlleContainerDivRef} style={{ display: 'flex', width: "100%", flexDirection: 'column', flex: 1 }}>
                    <div name="map_division" style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column', flex: 1, position: "relative" }}>
                        <div style={{ display: 'flex', width: "100%", height: "100%", position: "relative", minWidth: mapMinWidth, minHeight: mapMinHeight }}>
                            {
                                googleApiKey &&
                                <GoogleMapComponent
                                    ref={googleMapCompRef}
                                    zoom={zoom}
                                    center={mapData?.center}
                                    markers={mapData?.markers}
                                    pathCoordinates={mapData?.pathCoordinates} />
                            }
                            {
                                ready ?
                                    null :
                                    <div style={{ float: 'left', height: "100%", width: "100%", position: "absolute" }}>
                                    </div>
                            }
                        </div>
                        <div style={{
                            bottom: 0,
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Notificator ref={notificatorRef} />
                        </div>
                    </div>
                    <GridBar />
                    <div ref={eventGridRef} style={{ width: "100%", minHeight: minGridHeight, height: gridHeight, maxHeight: maxEventsgridHeightPx }}>
                        <div name="grid_division_resizer"
                            style={{
                                display: mobile ? "none" : undefined,
                                minHeight: "5px", height: "5px", width: "100%", cursor: "row-resize", backgroundColor: "whitesmoke"
                            }}
                            draggable={true}
                            onDragStart={(e) => {
                                const initialHeight = gridResizeData.height;
                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.initialPos = e.clientY;
                                newGridResizeData.initialHeight = initialHeight;
                                setGridResizeData(newGridResizeData);
                            }}
                            onDrag={(e) => {
                                if (e.clientY <= 0) { return; }
                                const initialPos = gridResizeData.initialPos;
                                const initialHeight = gridResizeData.initialHeight;
                                var newHeight = parseInt(initialHeight) - parseInt(e.clientY - initialPos);

                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.height = newHeight;
                                if (newHeight > maxEventsgridHeight) {
                                    newHeight = maxEventsgridHeight;
                                }

                                setGridResizeData(newGridResizeData);
                            }}

                            onDragEnd={(e) => {
                                const initialPos = gridResizeData.initialPos;
                                const initialHeight = gridResizeData.initialHeight;
                                var newHeight = parseInt(initialHeight) - parseInt(e.clientY - initialPos);
                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.height = newHeight;
                                setGridResizeData(newGridResizeData);
                            }}>
                        </div>
                        <div style={{ display: "flex", width: "100%", height: "100%", flex: 1 }}>
                            {
                                ready ?
                                    <EventsGridComponent
                                        ref={dataGridEventsGridRef}
                                        rows={eventsGridData}
                                        message={mapData?.userError}
                                        disabled={busy}
                                        gridHeight={gridHeight}
                                        onRowSelection={onGridRowSelection}>
                                        message={mapData?.userError}
                                    </EventsGridComponent> : null}
                        </div>
                    </div>
                </div>
                <div id='user_actions_resizable' name="user_actions_division"
                    style={{
                        width: userActionWidth, minWidth: accountPanelMinWidth, display: "flex", flexDirection: 'row',
                        height: "100%"
                    }}>
                    <div name="user_actions_division_resizer"
                        style={{
                            display: mobile ? "none" : undefined, backgroundColor: "whitesmoke",
                            minWidth: "5px", width: "5px", height: "100%", cursor: "col-resize"
                        }}
                        draggable={true}
                        onDragStart={(e) => {
                            const initialWidth = userActionResizeData.width;
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.initialPos = e.clientX;
                            newUserActionResizeData.initialWidth = initialWidth;
                            setUserActionResizeData(newUserActionResizeData);
                        }}
                        onDrag={(e) => {
                            if (e.clientX <= 0) { return; }
                            const initialPos = userActionResizeData.initialPos;
                            const initialWidth = userActionResizeData.initialWidth;
                            const newWidth = parseInt(initialWidth) - parseInt(e.clientX - initialPos);
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.width = newWidth;
                            console.log(`${newWidth},${e.clientX}, ${newWidth}`)
                            setUserActionResizeData(newUserActionResizeData);
                        }}

                        onDragEnd={(e) => {
                            const initialPos = userActionResizeData.initialPos;
                            const initialWidth = userActionResizeData.initialWidth;
                            const newWidth = parseInt(initialWidth) - parseInt(e.clientX - initialPos);
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.width = newWidth;
                            setUserActionResizeData(newUserActionResizeData);
                        }}>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%", overflowY: "hidden", }}>
                        <div ref={AccountDivRef} style={{ Height: "25px", width: "100%" }}>
                            <AccountBar />
                        </div>
                        <div ref={AssetDivRef} style={{ display: 'flex', minHeight: `${userActionMinHeight}px`, width: "100%", height: "100%", flex: 1 }}>
                            <UserPanelComponent
                                assets={activeAssets}
                                onSelection={onAssetSelection}
                                visible={isMobile ? userActionExpended : true}
                                accounts={activeAccounts}
                                onRequestCurrentPosition={(e) => {
                                    const asset = e.item;
                                    if (!asset)
                                        return;
                                    const filter = {
                                        assetId: asset.id
                                    }
                                    fetchRequestCurrentPosition((e) => {
                                        if (e.error)
                                            return;
                                        notificatorRef.current.pushNotification({
                                            notification: "Current position request was issued",
                                            color: "green"
                                        });
                                    }, filter);
                                }}
                                onRequestUpdate={(e) => {
                                    setUpdaterData({
                                        open: true,
                                        asset: e.item
                                    });
                                }}

                                onRequestFtpReport={(e) => {
                                    setFtpReportData({
                                        open: true,
                                        asset: e.item
                                    });
                                }}

                                selectedAccount={selectedAccount}
                                onAccountChange={(account) => {
                                    showLoading(true)
                                    setBusy(true)
                                    const m = { ...mapData };
                                    m.userError = "";
                                    m.markers = [];
                                    m.pathCoordinates = [];
                                    setEventsGridData([]);
                                    setMapData(m);
                                    setSelectedAccount(account)
                                    retrieveActiveAssets((state, assets) => {
                                        showLoading(false)
                                        setBusy(false)
                                        if (assets.length > 0) {
                                            const firstAsset = assets[0];
                                            setSelectedAsset(firstAsset)
                                            retrieveLastPositions(firstAsset.id)
                                        }
                                    });
                                }}
                                selectedAsset={selectedAsset}>
                            </UserPanelComponent>
                        </div>
                        <div style={{ display: "flex", height: timeFrameFollowSize, minHeight: timeFrameHeight }}>
                            <TimeFrameComponent
                                demo={true}
                                ref={timeFramePanelRef}
                                visible={userActionExpended}
                                onGo={onGo}
                                disabled={busy}>
                            </TimeFrameComponent>
                        </div>
                    </div>
                </div>
                {updaterData && <UpdaterDialog
                    onClickOutside={() => {
                        setUpdaterData(null)
                    }}
                    open={updaterData.open}
                    asset={updaterData.asset} />}
                {ftpReportData && <FtpReportDialog
                    onClickOutside={() => {
                        setFtpReportData(null)
                    }}
                    onFtpRequested={(e) => {
                        if (e.success) {
                            notificatorRef.current.pushNotification({
                                notification: `FTP ${e.modeName} report requested for ${e.asset.name}`,
                                color: "green",
                                lifetime: 4
                            });
                        }
                        else {
                            notificatorRef.current.pushNotification({
                                notification: "Error occurred while issuing request",
                                color: "red"
                            });
                        }
                    }}
                    open={ftpReportData.open}
                    asset={ftpReportData.asset} />}
                <NoInternet />
                <div className='middle-side-bar' style={{ backgroundColor: appThemeColor, width: sideBorderWidth }}>
                </div>
            </div>
            <div ref={bottomBarDivRef} style={{ backgroundColor: appThemeColor, height: barHeight, minHeight: barHeight, display: 'flex', position: "relative" }}>
                {isFullScreen && <TimeDisplay />}
            </div>
        </div>);
};

export default WebTraceDemoDashboard;