import { appConfig } from '../../../configurations/appConfig';
import urlJoin from 'url-join';

const createUser = (userData, onResult = null) => {
    const method = "users";
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`
        },
        method: 'POST',
        body: JSON.stringify(userData)
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

const updateUser = (userData, onResult = null) => {
    const method = "Users";
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`
        },
        method: 'PUT',
        body: JSON.stringify(userData)
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

const deleteUser = (onResult = null, userId) => {
    const method = `Users/${userId}`;
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`
        },
        method: 'DELETE',
        //body: JSON.stringify(userData)
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

const changeUserPassword = (passwordChangeData, onResult = null) => {
    const method = "Users/password-change";
    const serverBasePath = appConfig.data.serverBasePath;
    const server = appConfig.data.server;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`
        },
        method: 'POST',
        body: JSON.stringify(passwordChangeData)
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};


export { createUser, updateUser, deleteUser, changeUserPassword };