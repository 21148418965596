import React from 'react'
import { isMobile } from 'react-device-detect';
import { lang } from '../../language/lang';
class ConfirmBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { closed: false };
    }

    render() {
        const mobile = isMobile;
        const titleFontSize = mobile ? "12px" : "14px";
        const messageFontSize = mobile ? "10px" : "12px";

        return (<div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <dialog open={this.props.open && !this.state.closed} style={{ padding: '3px', color: "black", position: "relative", borderWidth: "0.3px", zIndex: 25, transform: `translate(-50%, -50%)` }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ margin: "0px 0px 10px 0px", height: "15px", fontSize: titleFontSize }}>{this.props.title}</div>
                    <div style={{ margin: "0px 0px 10px 0px", height: "15px", fontSize: messageFontSize }}>{this.props.message}</div>
                    <div style={{ textAlign: "left", display: "flex", flexDirection: "row", gap: "6px" }}>
                        <button style={{
                            width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                            borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                        }} onClick={() => {
                            if (this.props?.onConfirm) {
                                this.props.onConfirm(this.props.data);
                            }
                        }}>confirmer</button>
                        <span style={{ flexGrow: 1, display: "flex" }}></span>
                        <button style={{
                            width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                            borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                        }} onClick={() => {
                            if (!this.props?.onCancel) {
                                const s = { ...this.state }
                                s.closed = true;
                                this.setState(s);
                                return;
                            }
                            this.props.onCancel();
                        }}>Annuler</button>
                    </div>
                </div>
            </dialog>
        </div>);
    }
}

export default ConfirmBox;