import React from 'react'
import SimcardManager from './SimcardManager.js';
import SimcardSmsList from './SimcardSmsList.js';
import SimcardUssdList from './SimcardUssdList.js';
import "./SimcardOperator.css"


function SimcardOperator(props) {
    const [tab, setTab] = React.useState('simcard');
    const [busy, setBusy] = React.useState(false);

    React.useEffect(() => {
    }, []);
    if (busy) {
        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                    <div style={{ height: "40px", width: "100%" }}>
                        <div style={{ display: "grid", flexDirection: "row", gap: "5px", gridTemplateColumns: "repeat(3, 1fr)" }}>
                            <button className={tab === 'simcard' ? "simcard_operator_active_tab_header" : "simcard_operator_tab_header"}
                                onClick={() => {
                                    setTab('simcard')
                                }}>Simcards Manager</button>
                            <button
                                className={tab === 'sms' ? "simcard_operator_active_tab_header" : "simcard_operator_tab_header"}
                                onClick={() => {
                                    setTab('sms')
                                }}>Sms Manager</button>
                            <button
                                className={tab === 'ussd' ? "simcard_operator_active_tab_header" : "simcard_operator_tab_header"}
                                onClick={() => {
                                    setTab('ussd')
                                }}>Ussd Manager</button>
                        </div>
                    </div>
                    {
                        tab === 'simcard' &&
                        <SimcardManager></SimcardManager>
                    }
                    {
                        tab === 'sms' &&
                        <SimcardSmsList></SimcardSmsList>
                    }
                    {
                        tab === 'ussd' &&
                        <SimcardUssdList></SimcardUssdList>
                    }
                </div>
            </div>
            {/* {simcardNumberSetup?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <SetupSimcardNumber
                    {...simcardNumberSetup}
                    onSuccess={() => {
                        setSimcardNumberSetup(null)
                        setRefresh(true)
                    }}
                    onClose={() => {
                        setSimcardNumberSetup(null)
                    }} />
            </div>} */}
        </div>)
}


export default SimcardOperator;