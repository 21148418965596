import React from 'react'
import { isMobile } from 'react-device-detect';
import { AppContext } from '../../../AppContext';
import { localTimeFormat } from '../../../utilities/timeHelper'
import { buildAssetColorIconUrl } from '../../../utilities/assetHelper'
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors';
import { showLoading } from 'react-global-loading';
import fetchAssets from "../../../calls/administration/assets/fetchAssets";

import fetchFakeAssets from '../../../calls/administration/assets/fetchFakeAssets';
import ConfirmBox from '../ConfirmBox';
import AssetCreation from "./AssetCreation"
import deleteAsset from "../../../calls/administration/assets/deleteAsset";
import Paginator from '../../Paginator/Paginator';
import SearchFilter from '../../SearchFilter/SearchFilter';


function AssetsListView(props) {
    const { setGlobalError } = React.useContext(AppContext);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [busy, setBusy] = React.useState(false);
    //const [assets, setAssets] = React.useState([]);
    const [assetCreation, setAssetCreation] = React.useState(null);
    const [assetDeletion, setAssetDeletion] = React.useState(null);
    const [mustUpdate, setMustUpdate] = React.useState(false);

    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState(null);

    const updateAssets = (silent = true) => {
        const filter = {
            pageNumber: pageNumber?.value,
            search: searchFilter?.value ?? ""
        }

        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchAssets((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }

            if (e.error) {
                setGlobalError(e.error)
                return;
            }

            setData(e.data)
        }, filter);
    }

    React.useEffect(() => {
        updateAssets(false)
    }, [])

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const filter = { ...pageNumber }
            filter.updated = false;
            setPageNumber(filter)
            updateAssets(true);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateAssets(true);
        }
    }, [searchFilter]);

    React.useEffect(() => {
        if (mustUpdate) {
            setMustUpdate(false);
            updateAssets(false)
        }

    }, [mustUpdate])

    React.useEffect(() => {
        if (props.updateFlag) {
            updateAssets(false)
            if (props.onUpdateFlagHandled)
                props.onUpdateFlagHandled()
        }

    }, [])

    if (busy) return null;
    const assets = data?.data;
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
            <div id='topbar' n style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                <div style={{ flex: 1 }}></div>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
            </div>
            <div style={{ width: "100%", flexFlow: "column", display: "flex", flex: 1 }}>
                <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                    <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                        <table style={{ width: "100%", fontSize: "14px" }}>
                            <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                <tr>
                                    <th style={{ textAlign: "left", }}>Name</th>
                                    <th style={{ textAlign: "left", }}>Type</th>
                                    <th style={{ textAlign: "left" }}>Account</th>
                                    <th style={{ textAlign: "left" }}>Created</th>
                                    <th style={{ textAlign: "left" }}>Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    assets &&
                                    assets.map((asset, index) => {
                                        const finalAsset = asset;
                                        const isSelected = (selectedAsset && finalAsset.id === selectedAsset?.id);
                                        const mobile = isMobile;
                                        const imgSize = mobile ? "10px" : "15px";
                                        const iconSourceUrl = buildAssetColorIconUrl(finalAsset.assetType, finalAsset.assetTypeIconColor)
                                        return (
                                            <tr key={index}
                                                style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }} onClick={() => {
                                                    setSelectedAsset(finalAsset)
                                                }}>
                                                <td style={{ textAlign: "left" }}>{finalAsset.name}</td>
                                                <td style={{ textAlign: "left" }}>
                                                    <span style={{
                                                        alignItems: "center",
                                                        width: "100%", height: "100%", justifyContent: "left", display: "flex"
                                                    }}>
                                                        <img style={{ margin: "0px 5px", width: imgSize, height: imgSize }} src={iconSourceUrl} alt="Logo" />
                                                        {finalAsset.assetType}
                                                    </span>
                                                </td>
                                                <td style={{ textAlign: "left" }}>{finalAsset.account}</td>
                                                <td style={{ textAlign: "left" }}>{localTimeFormat(finalAsset.createdAt)}</td>
                                                <td style={{ textAlign: "left" }}>{localTimeFormat(finalAsset.updatedAt)}</td>
                                            </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    Boolean(data?.pageCount && data?.pageCount > 1) &&
                    <div style={{ alignItems: "end", display: "flex", justifyContent: "end", marginRight: "20px" }}>
                        <Paginator
                            changeIndicator={data}
                            initialNumber={pageNumber?.value}
                            pageSize={data?.pageSize}
                            pageCount={data?.pageCount}
                            count={data?.count}
                            onPageSelected={(page) => {
                                console.log(page)
                                setPageNumber({
                                    value: page.number,
                                    updated: true
                                })
                            }} />
                    </div>
                }
            </div>
            <div style={{ height: "30px", display: "flex", padding: "2px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    onClick={() => {
                        setAssetCreation({
                            open: true,
                        })
                    }}>
                    Create
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAsset}
                    onClick={() => {
                        setAssetCreation({
                            open: true,
                            editAsset: assets.find(x => x.id == selectedAsset?.id)
                        })
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAsset}
                    onClick={() => {
                        setAssetDeletion({
                            open: true,
                            deleteAsset: selectedAsset
                        })
                    }}>
                    Delete
                </button>
            </div>
            {
                assetCreation?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <AssetCreation
                        options={assetCreation}
                        onAssetEdited={() => {
                            setAssetCreation(null)
                            setMustUpdate(true);
                        }}
                        onAssetCreated={() => {
                            setAssetCreation(null)
                            setMustUpdate(true);
                        }}
                        onCancel={() => {
                            setAssetCreation(null)
                        }}
                    />
                </div>
            }
            {
                assetDeletion?.open &&
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ConfirmBox
                        open={assetDeletion?.open}
                        title="Asset deletion"
                        onCancel={() => {
                            setAssetDeletion(null)
                        }}
                        onConfirm={() => {
                            const asset = assetDeletion.deleteAsset;
                            setAssetDeletion(null)
                            showLoading(true);
                            deleteAsset((e) => {
                                showLoading(false);
                                if (e.error) {
                                    setGlobalError(e.error)
                                    return;
                                }

                                updateAssets(false)
                            }, asset?.id);
                        }}
                        message={`You are about to delete account ${assetDeletion?.deleteAsset?.name}`} />
                </div>
            }
        </div>);
}

export default AssetsListView;