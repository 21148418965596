import React from 'react'
import fetchFtpReportInfo from '../../calls/ftp/fetchFtpReportInfo';
import downloadFtpReport from '../../calls/ftp/downloadFtpReport';
import requestNewFtpReport from '../../calls/ftp/requestNewFtpReport';
import { toLocalTimeFormatted } from '../../utilities/timeHelper';
import { DropdownList } from 'react-widgets';

const modes = [
    { name: "Basic", value: 55 },
    { name: "Extended", value: 56 },
    { name: "Ultimate", value: 58 },
];

function FtpReportDialog(props) {
    const [reportInfo, setReportInfo] = React.useState(null);
    const [selectedMode, setSelectedMode] = React.useState(null);
    const wrapperRef = React.useRef(null);


    const updateFtpInfo = (onResult = null) => {
        const assetId = props.asset?.id
        if (!assetId) return
        fetchFtpReportInfo((e) => {
            if (e.error) {
                if (onResult)
                    onResult(false)
                return;
            }

            const data = e.data;
            setReportInfo(data)
            if (onResult)
                onResult(data?.exists)
        }, assetId)
    }

    const downloadFtpReportRequest = () => {
        const assetId = props.asset?.id;
        const filename = reportInfo?.fileName
        if (!assetId) return
        downloadFtpReport((e) => {
            if (e.error) {
                return;
            }
            const blob = e.data;
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
        }, assetId)
    }

    const requestNewFtpReportRequest = () => {
        const asset = props.asset;
        const assetId = asset?.id;
        const mode = selectedMode?.value ?? 55
        const modeName = modes.find(x => x.value === mode)
        if (!assetId) return
        const requestData = {
            assetId: assetId,
            New: false,
            mode: selectedMode?.value ?? 55
        };

        requestNewFtpReport((e) => {
            if (e.error) {
                if (props.onFtpRequested)
                    props.onFtpRequested({
                        asset: asset,
                        success: false,
                        error: e.error,
                        modeName: modeName
                    });
                return;
            }

            if (props.onFtpRequested)
                props.onFtpRequested({
                    asset: asset,
                    success: true,
                    modeName: modeName
                });

        }, requestData)
    }

    React.useEffect(() => {
        updateFtpInfo((e) => {
            if (!e) {
                setSelectedMode(modes[0])
            }
        });
        console.log(props)
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if (props.onClickOutside)
                    props.onClickOutside()
            }
        };

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };

    }, []);

    return <dialog open={props.open}
        focusout={() => {
            console.log("");
        }}
        style={{
            position: "fixed",
            right: 0, left: 0, top: 0, bottom: 0,
            padding: "5px", minWidth: "280px",
            borderRadius: "5px",
            borderWidth: "1px"
        }}>
        <div ref={wrapperRef} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <span>{props.asset.name} FTP Report</span>
            <div style={{ backgroundColor: 'lightgray', height: '1.5px', margin: "8px 0" }} />
            <div style={{ gap: "10px", display: "flex", flexDirection: "row" }}>
                {
                    reportInfo?.exists &&
                    <div style={{ display: "flex", flexDirection: "column", textAlign: "left", flex: 1 }}>
                        <span style={{ fontWeight: "bold" }}>{reportInfo?.fileName}</span>
                        <span style={{ fontSize: "11px" }}>{reportInfo?.length} bytes</span>
                        <span style={{ fontSize: "11px" }}>{toLocalTimeFormatted(reportInfo?.date)}</span>
                    </div>
                }
                {
                    !reportInfo?.exists &&
                    <div style={{ textAlign: "left", display: "flex", flex: 1 }}>
                        No current report found !
                    </div>
                }
                <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
                    {
                        reportInfo?.exists &&
                        <button onClick={() => {
                            downloadFtpReportRequest();
                            if (props.onClickOutside)
                                props.onClickOutside()
                        }}>
                            View
                        </button>
                    }
                </div>
            </div>
            <div style={{ backgroundColor: 'lightgray', height: '1.5px', margin: "8px 0" }} />
            <div style={{ gap: "10px", display: "flex", flexDirection: "column" }}>
                <DropdownList
                    readOnly={false}
                    placeholder='Select FTP request mode'
                    //dropUp
                    textField="name"
                    dataKey="value"
                    data={modes}
                    value={selectedMode}
                    filter={false}
                    onChange={(e) => {
                        setSelectedMode(e)
                    }}
                    autoFocus={false}
                />
                <button disabled={!selectedMode} onClick={() => {
                    requestNewFtpReportRequest();
                    if (props.onClickOutside)
                        props.onClickOutside()
                }}>
                    Request New
                </button>
            </div>
        </div>
    </dialog>;
}

export default FtpReportDialog;