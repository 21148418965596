import { showLoading } from 'react-global-loading';
import React from 'react'
import updateEvent from "../../../calls/administration/events/updateEvent";
import createEvent from "../../../calls/administration/events/createEvent";

import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";

import "./EventsListView.css"

function EventCreation(props) {
    const [code, setCode] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        if (!props.open) return;
        setError("")
        if (props.editEvent) {
            setCode(props.editEvent.code?.toString() ?? "");
            setDescription(props.editEvent.description.toString() ?? "")
        }
        else {
            setCode("")
            setDescription("")
        }

    }, [props.open]);

    return <dialog open={props.open}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
        }}>
        <div>
            <div style={{ textAlign: "right" }}>
                Event creation
            </div>
            <hr />
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="event_creation_code">Code</label>
                <input style={{ display: "block", width: "100%" }} type="number" id="event_creation_code" placeholder="Event code" value={code}
                    autoComplete="off"
                    disabled={props.editEvent}
                    onChange={(e) => {
                        setCode(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="event_creation_description">Description</label>
                <textarea style={{ display: "block", width: "100%", resize: "none" }} type="text" id="event_creation_description" placeholder="Event description" value={description}
                    autoComplete="off"
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }} />
            </div>

            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (checkIfEmptyOrWhiteSpace(code)) {
                        setError("Code cannot be empty")
                        return;
                    }

                    if (checkIfEmptyOrWhiteSpace(description)) {
                        setError("Description cannot be empty")
                        return;
                    }

                    showLoading(true)
                    if (props.editEvent) {
                        const data = {
                            code: code,
                            description: description,
                            comment: "New life",
                            eventId: props.editEvent.id
                        };

                        updateEvent(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (props.onEventCreated)
                                props.onEventCreated(e.data)
                        });
                    }
                    else {
                        const data = {
                            code: code,
                            description: description,
                            comment: "New life"
                        };

                        createEvent(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (props.onEventCreated)
                                props.onEventCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) return;
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export default EventCreation;