import { isMobile } from 'react-device-detect';
import React from 'react'
import DataGrid from 'react-data-grid';
import { Row } from 'react-data-grid';
import { createContext, useRef } from 'react';

import { AppContext } from "../AppContext";
import { lang } from '../language/lang';
import { getPeriodElapsed, timeSpanFromHours, timeSpanFromDays } from '../utilities/dateHelper';
import { forwardRef, useImperativeHandle } from 'react';
import { buildAssetColorIconUrl } from '../utilities/assetHelper'
export const EventsDataGridRowContext = createContext({});


const periods3h = timeSpanFromHours(3);
const periods3d = timeSpanFromDays(3);

const timeSentFormatter = ({ row }) => {
    const time = getPeriodElapsed(row.timeIndex);
    const sent = row.timesent ? row.timesent : undefined;
    if (time < periods3h)
        return <span style={{ color: "limegreen", textAlign: "right" }}>{sent}</span>
    if (time < periods3d)
        return <span style={{ color: "orangered", textAlign: "right" }}>{sent}</span>
    return <span style={{ color: "red", textAlign: "right" }}>{sent}</span>
}



function AssetFormatter({ row }) {
    const mobile = isMobile;
    const imgSize = mobile ? "10px" : "15px";
    const asset = row?.assetData;
    const src = buildAssetColorIconUrl(asset.assetType, asset.assetTypeIconColor);
    return <span style={{
        alignItems: "center", width: "100%", height: "100%", justifyContent: "left", display: "flex"
    }}  >
        <img style={{ margin: "0px 5px", width: imgSize, height: imgSize }} src={src} alt="Logo" />
        {row.asset}
    </span>
}

const getColumns = () => {
    return [
        {
            key: 'date', name: lang.language.DateHeader
        },
        {
            key: 'hour', name: lang.language.HourHeader
        },
        {
            key: 'asset',
            name: lang.language.AssetHeader,
            renderCell: AssetFormatter,
            maxWidth: 550
        },
        {
            key: 'event', name: lang.language.EventHeader
        },
        {
            key: 'heading', name: lang.language.HeadingHeader
        },
        {
            key: 'speed', name: lang.language.SpeedHeader
        },
        {
            key: 'location',
            name: lang.language.LocationHeader,
            maxWidth: 1200
        },
        {
            key: 'state', name: lang.language.StateHeader,
        },
        {
            key: 'timesent',
            name: lang.language.TimeSent,
            renderCell: timeSentFormatter,
        },
    ]
};

function rowKeyGetter(row) {
    return row.id;
}

function EventsGridComponent(props, ref) {
    const { gridSelectedRowId } = React.useContext(AppContext);
    const { setMakerSelectedId } = React.useContext(AppContext);
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [scrollRowIdx, setScrollRowIdx] = React.useState(0);

    const gridRef = useRef(null);

    React.useEffect(() => {
        setRows([...props.rows])
    }, [props.rows]);

    React.useEffect(() => {
        setSelectedGridRows(new Set([gridSelectedRowId]));
    }, [gridSelectedRowId]);

    React.useEffect(() => {
        if (gridRef?.current?.scrollToRow)
            gridRef.current.scrollToRow(scrollRowIdx);
        if (gridRef?.current?.scrollToCell)
            gridRef.current.scrollToCell({ rowIdx: scrollRowIdx });
    }, [scrollRowIdx]);


    const scrollToRow = (rowIdx) => {
        setScrollRowIdx(rowIdx)
    }

    useImperativeHandle(ref, () => ({
        scrollToRow: scrollToRow,
    }));

    const mobile = isMobile;
    const gridRowHeight = mobile ? 15 : 20;

    const RowRenderer = (key, props) => {

        return <Row key={key} {...props}
            selectedCellIdx={undefined} />
    };

    return (
        <div style={{ width: "100%", position: "relative", height: "100%", scrollbarWidth: "none", display: "flex" }}>
            <DataGrid
                ref={gridRef}
                defaultColumnOptions={{
                    sortable: false,
                    resizable: isMobile ? false : true,
                    minWidth: 100,
                    maxWidth: 220
                }}
                rowHeight={gridRowHeight}
                columns={columns}
                rows={rows}
                rowKeyGetter={rowKeyGetter}
                className='rdg-light'
                onSelectedRowsChange={setSelectedGridRows}
                selectedRows={selectedGridRows}
                onRowsChange={setRows}
                renderers={{ renderRow: RowRenderer }}
                enableVirtualization={true}
                style={{ width: "100%", height: "calc(100% - 5px)", position: "absolute" }}
                onCellClick={(args, event) => {
                    const rowId = args.row.id;
                    var set = new Set([rowId]);
                    setSelectedGridRows(set);
                    setMakerSelectedId(rowId);
                    if (props.onRowSelection) {
                        props.onRowSelection(rowId);
                    }
                }}
            />
            {props.message && <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "orangered",
                    whiteSpace: "pre-line",
                    //fontWeight: "bold"
                }}>
                {props.message}
            </div>}
        </div>
    );
}

export default forwardRef(EventsGridComponent);