import React from 'react'
import './Paginator.css'
function Paginator(props) {
    const [selectedPage, setSelectedPage] = React.useState({
        number: 1
    });
    const [pages, setPages] = React.useState([]);

    const buildPages = () => {
        const maxPageCount = props.pageCount;
        const visibleCount = props.visibleCount ?? 6;
        const half = visibleCount / 2
        const currentNumber = selectedPage.number
        const pages = []
        if (currentNumber - half <= 1) {
            for (let i = 1; i <= visibleCount && i <= maxPageCount; i++) {
                pages.push({
                    number: i
                })
            }
        }
        else if (currentNumber + half > maxPageCount) {
            for (let i = maxPageCount - visibleCount; i <= maxPageCount; i++) {
                if (i > 0) {
                    pages.push({
                        number: i
                    })
                }
            }
        }
        else {
            for (let i = currentNumber - half; i < currentNumber; i++) {
                if (i > 0) {
                    pages.push({
                        number: i
                    })
                }
            }

            for (let i = currentNumber + 1; i < currentNumber + half; i++) {
                pages.push({
                    number: i
                })
            }
        }

        return pages;
    }

    React.useEffect(() => {
        setSelectedPage({
            number: props.initialNumber ?? 1,
            updated: true
        })
    }, []);

    React.useEffect(() => {
        if (props.pageCount) {
            setPages(buildPages())
        }
        else {
            setPages([])
        }

    }, [props.changeIndicator]);

    React.useEffect(() => {
        if (selectedPage?.updated) {
            if (props.pageCount) {
                setPages(buildPages())
            }
            else {
                setPages([])
            }
        }
    }, [selectedPage]);

    React.useEffect(() => {
        if (pages && pages.length > 0 && selectedPage) {
            const p = pages.find(x => x.number == selectedPage.number);
            if (!p) {
                const max = pages.reduce(function (prev, current) {
                    return (prev && prev.number > current.number) ? prev : current
                }) //r
                if (max) {
                    setSelectedPage({ ...max, updated: false })
                }
            }
        }
    }, [pages]);
    const isPaginatorValid = pages && (pages.length > 0);
    return (
        <div className='pagination'>
            <a href="#"
                style={!isPaginatorValid ? { pointerEvents: 'none' } : {}}
                onClick={(e) => {
                    e.preventDefault();
                    const page = {
                        number: 1,
                        updated: true
                    };

                    setSelectedPage({ ...page, updated: true })
                    if (props.onPageSelected)
                        props.onPageSelected(page)
                }}>&laquo;</a>
            {
                isPaginatorValid &&
                pages.map((page, index) => {
                    const finalPage = page;
                    const isSelected = (selectedPage && selectedPage.number === finalPage.number);
                    return (
                        <a key={index} href="#"
                            className={isSelected ? "active" : ""}
                            onClick={(e) => {
                                const p = { ...page, updated: true }
                                e.preventDefault();
                                console.log(p);
                                setSelectedPage(p)
                                if (props.onPageSelected)
                                    props.onPageSelected(p)
                            }}>
                            {finalPage.number}
                        </a>);
                })
            }
            <a href="#"
                style={!isPaginatorValid ? { pointerEvents: "none" } : {}}
                onClick={(e) => {

                    e.preventDefault();
                    const maxPageCount = props.pageCount;
                    const page = {
                        number: maxPageCount
                    };

                    setSelectedPage({ ...page, updated: true })
                    if (props.onPageSelected)
                        props.onPageSelected(page)
                }}>&raquo;</a>
            {/* <a href="#">&laquo;</a>
            <a href="#">1</a>
            <a class="active" href="#">2</a>
            <a href="#">3</a>
            <a href="#">4</a>
            <a href="#">5</a>
            <a href="#">6</a>
            <a href="#">&raquo;</a> */}
        </div>
    );
}

export default Paginator;