import { appConfig } from '../../configurations/appConfig'
import urlJoin from 'url-join';


const fetchPositions = (onResult = null, queryFilter) => {
    const searchParams = new URLSearchParams();
    if (queryFilter?.assetId) searchParams.append("assetId", queryFilter.assetId);
    if (queryFilter?.startDate) searchParams.append("startDate", queryFilter.startDate.toISOString());
    if (queryFilter?.endDate) searchParams.append("endDate", queryFilter.endDate.toISOString());
    if (queryFilter?.sort) searchParams.append("sort", queryFilter.sort);
    const method = "positions?" + searchParams;
    const server = appConfig.data.server;
    const authorisationToken = appConfig.authorisationToken;
    const serverBasePath = appConfig.data.serverBasePath;
    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${authorisationToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET'
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};


export default fetchPositions;