import { appConfig } from '../../configurations/appConfig';
import urlJoin from 'url-join';

const login = (loginData, onResult = null) => {
    const method = "authentication/login";
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    const requestOptions = {
        headers: {
            'Accept': 'application/json',

            'Content-Type': 'application/json'
        },
        credentials: "include",
        method: 'POST',
        body: JSON.stringify(loginData)
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

export default login;