import React from 'react'
import { GrClose } from "react-icons/gr";
import { IconContext } from "react-icons";
import { isMobile } from 'react-device-detect';
import { showLoading } from 'react-global-loading';
import { AppContext } from '../../../AppContext';
import fetchTwilioRequests from "../../../calls/administration/twilio/fetchTwilioRequests";
import SearchFilter from '../../SearchFilter/SearchFilter'
import { localTimeFormat } from '../../../utilities/timeHelper'
import { truncate } from '../../../utilities/stringHelper'
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors'
import { getTwilioMessageStatus, getTwilioMessageDirection } from '../../../utilities/twilioHelper'


function TwilioListView(props) {
    const { setGlobalError } = React.useContext(AppContext);
    const [busy, setBusy] = React.useState(false);
    const [twilioRequests, setTwilioRequests] = React.useState([]);
    const [selectedTwilioRequest, setSelectedTwilioRequest] = React.useState(null);
    const [twilioRequestView, setTwilioRequestView] = React.useState([]);

    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState(null);

    const updateTwilioRequests = (silent = true, onComplete) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        const filter = {
            search: searchFilter?.value ?? ""
        }

        fetchTwilioRequests((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                setGlobalError(e.error)
                if (onComplete) onComplete(false);
                return;
            }

            setTwilioRequests(e.data.data)
            //{id: 1, direction: 2, from: '+15044147554', to: '+243821232175', messageSid: 'SMe537133025ee47cdb8ca3090f986f4fa', �}


            if (onComplete)
                onComplete(true);
        }, filter);
    }

    React.useEffect(() => {
        updateTwilioRequests(false, () => {
        });

    }, []);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const page = { ...pageNumber }
            page.updated = false;
            setPageNumber(page)
            updateTwilioRequests(true);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateTwilioRequests(true, () => {

            });
        }
    }, [searchFilter]);

    if (busy) {
        return null;
    }

    return (
        <div style={{
            width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column"
        }} >
            <div id='topbar' n style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                <div style={{ flex: 1 }}></div>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
            </div>
            <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                    <table style={{ width: "100%", fontSize: "14px" }}>
                        <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                            <tr>
                                <th style={{ textAlign: "left", }}>Direction</th>
                                <th style={{ textAlign: "left", }}>To</th>
                                <th style={{ textAlign: "left", }}>Body</th>
                                <th style={{ textAlign: "left" }}>status</th>
                                <th style={{ textAlign: "left" }}>Date sent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                twilioRequests &&
                                twilioRequests.map((request, index) => {
                                    const finalRequest = request;
                                    const isSelected = (selectedTwilioRequest && finalRequest.id === selectedTwilioRequest?.id);
                                    const timeSent = finalRequest.dateSent ? localTimeFormat(finalRequest.dateSent) : '_';
                                    return (
                                        <tr key={index}
                                            style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                            onClick={() => {
                                                setSelectedTwilioRequest(finalRequest)
                                            }}>
                                            <td style={{ textAlign: "left" }}>{getTwilioMessageDirection(finalRequest.direction)}</td>
                                            <td style={{ textAlign: "left" }}>{finalRequest.to}</td>
                                            <td style={{ textAlign: "left" }}>{truncate(finalRequest.body, 50)}</td>
                                            <td style={{ textAlign: "left" }}>{getTwilioMessageStatus(finalRequest.status)}</td>
                                            <td style={{ textAlign: "left" }}>{timeSent}</td>
                                        </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{ height: "30px", width: "100%", display: "flex", padding: "10px 0px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedTwilioRequest}
                    onClick={() => {
                        setTwilioRequestView({
                            open: true,
                            twilioRequest: selectedTwilioRequest
                        })
                    }}>
                    View
                </button>
            </div>
            {twilioRequestView?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <ViewTwilioMessage
                    {...twilioRequestView}
                    onClose={() => {
                        setTwilioRequestView(null)
                    }} />
            </div>}
        </div>);
}



function ViewTwilioMessage(props) {
    const awesomeSize = isMobile ? 18 : 14;
    const twilioRequest = props.twilioRequest;
    return (
        <dialog open={true}
            style={{
                padding: "10px",
                borderWidth: "1px",
                width: "400px",
                borderRadius: "5px", display: "flex", flexDirection: "column", gap: "10px"
            }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                <div style={{ width: "100%", display: "flex" }}>
                    Twilio message request
                </div>
                <button style={{ backgroundColor: "transparent", borderWidth: "0px", height: "100%" }}
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose();
                        }
                    }}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <IconContext.Provider value={{ size: awesomeSize }}>
                            <GrClose />
                        </IconContext.Provider>
                    </div>
                </button>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
                From {twilioRequest?.from}
            </div>
            <div style={{ width: "100%", display: "flex" }}>
                To {twilioRequest?.to}
            </div>
            <span style={{ textAlign: "left" }}>
                {twilioRequest.body}
            </span>
            <button
                onClick={() => {
                    if (props.onClose) {
                        props.onClose();
                    }
                }}>
                Ok
            </button>
        </dialog>
    );
}

export default TwilioListView;