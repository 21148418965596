import React from 'react'
import { showLoading } from 'react-global-loading';
import DropdownList from "react-widgets/DropdownList";

import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";
import createContact from "../../../calls/administration/contacts/createContact";
import updateContact from "../../../calls/administration/contacts/updateContact";
import fetchAccounts from "../../../calls/administration/accounts/fetchAccounts";

function ContactCreation(props) {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [error, setError] = React.useState("");

    const [accounts, setAccounts] = React.useState([]);
    const [accountSelection, setAccountSelection] = React.useState(null);
    const [accountSearchTerm, setAccountSearchTerm] = React.useState('');

    const [ready, setReady] = React.useState(false);

    const editContact = props?.options?.editContact;
    const open = props.options?.open;

    const updateAccounts = (onResult, filter) => {
        fetchAccounts((e) => {
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setAccounts(e.data?.data);
            if (onResult) onResult(true, e?.data?.data);
        }, filter);
    }
    const init = () => {
        const filter = {
            count: 50,
            additionalIds: editContact?.accountId ? [editContact.accountId] : null
        };

        updateAccounts((status, accounts) => {
            setReady(true)
            if (editContact) {
                setName(editContact.name)
                setEmail(editContact.email)
                setNumber(editContact.number)
                setAddress(editContact.adress)
                if (accounts) {
                    const selected = accounts.find(x => x.id === editContact.accountId);
                    setAccountSelection(selected);
                }
            }
        }, filter);
    }

    React.useEffect(() => {
        init();
        return () => {
            setError("")
            setName("")
            setEmail("")
            //setEditContact(null)
        }
    }, []);

    if (!ready) return null;

    return <dialog open={props.open}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
        }}>
        <div>
            <div style={{ textAlign: "right" }}>
                Contact creation
            </div>
            <hr />
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="contact_creation_name">Name</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="contact_creation_name" placeholder="Name" value={name}
                    autoComplete="off"
                    onChange={(e) => {
                        setName(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="contact_creation_email">Email</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="contact_creation_email" placeholder="Email" value={email}
                    autoComplete="off"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="contact_creation_telephone">Telephone</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="contact_creation_telephone" placeholder="Number" value={number}
                    autoComplete="off"
                    onChange={(e) => {
                        setNumber(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="contact_creation_address">Address</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="contact_creation_address" placeholder="Address" value={address}
                    autoComplete="off"
                    onChange={(e) => {
                        setAddress(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "140px", textAlign: "left" }} htmlFor="asset_creation_account">Account</label>
                <DropdownList
                    readOnly={editContact}
                    id="asset_creation_account"
                    style={{ display: "block", width: "100%" }}
                    data={accounts}
                    dataKey='id'
                    textField='name'
                    value={accountSelection}
                    onChange={(value) => {
                        setAccountSelection(value)
                    }}
                    searchTerm={accountSearchTerm}
                    onSearch={(e) => {
                        setAccountSearchTerm(e);
                        const filter = {
                            count: 50,
                            searchTerm: e,
                            additionalIds: editContact?.accountId ? [editContact.accountId] : null
                        };

                        updateAccounts(() => {
                        }, filter);
                    }}
                />
            </div>

            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (checkIfEmptyOrWhiteSpace(name)) {
                        setError("Username cannot be empty")
                        return;
                    }

                    if (editContact) {
                        const data = {
                            contactId: editContact.id,
                            name: name,
                            email: email,
                            number: number,
                            address: address,
                            accountId: accountSelection?.id,
                            comment: "New life"
                        };

                        showLoading(true)
                        updateContact(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (e.data.error) {
                                setError(e.data.error)
                                return;
                            }

                            if (!props?.onContactedited) {
                                return;
                            }

                            props?.onContactedited(e.data)
                        });
                    }
                    else {
                        const data = {
                            name: name,
                            email: email,
                            number: number,
                            address: address,
                            accountId: accountSelection?.id,
                            comment: "New life"
                        };

                        showLoading(true)
                        createContact(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }
                            if (e.data.error) {
                                setError(e.data.error)
                                return;
                            }

                            if (!props?.onContactCreated) {
                                return;
                            }

                            props?.onContactCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export default ContactCreation;