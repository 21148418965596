const getTwilioMessageStatus = (status) => {
    if (status == 1) return "accepted";
    if (status == 2) return "scheduled";
    if (status == 3) return "queued";
    if (status == 4) return "sending";
    if (status == 5) return "sent";
    if (status == 6) return "delivery_unknown";
    if (status == 7) return "delivered";
    if (status == 8) return "undelivered";
    if (status == 9) return "failed";
    return "__"
}

const getTwilioMessageDirection = (status) => {
    if (status == 0) return "None";
    if (status == 1) return "Inbound";
    if (status == 2) return "Outgoing";
    return "__"
}

export { getTwilioMessageStatus, getTwilioMessageDirection }