import { appConfig } from '../../configurations/appConfig'
import urlJoin from 'url-join';

const downloadFtpReport = (onResult = null, assetId) => {
    const method = "ftpreports/download?" + new URLSearchParams({
        assetId: assetId,
    });
    const server = appConfig.data.server;
    const authorisationToken = appConfig.authorisationToken;
    const serverBasePath = appConfig.data.serverBasePath;
    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${authorisationToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                response.blob().then(blob => {
                    if (!onResult) return;
                    onResult({ data: blob });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

export default downloadFtpReport