import { appConfig } from '../../../configurations/appConfig';
import urlJoin from 'url-join';

const fetchAccountContacts = (onResult = null, accountId) => {
    const searchParams = new URLSearchParams();
    if (accountId) searchParams.append("accountId", accountId);
    const serverBasePath = appConfig.data.serverBasePath;
    const method = "Accounts/retrieve_account_contacts?" + searchParams;
    const authorisationToken = appConfig.authorisationToken;
    const server = appConfig.data.server;
    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${authorisationToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text().then(text => {
                    if (!onResult) return;
                    onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                }).catch(err => {
                    console.info(`${err}`);
                    if (!onResult) return;
                    onResult({ error: `${err}`, data: null });
                });
            }
        })
        .catch(err => {
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

export default fetchAccountContacts