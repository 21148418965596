import React from 'react'
import { timeSpanFromHours } from "../../../utilities/dateHelper";
import { hexToRgbaWithAlpha } from "../../../utilities/convertColors";
import { truncateWithEllipses } from "../../../utilities/stringHelper";
import {
    fetchUssd,
} from "../../../calls/administration/ussd/fetchUssd.js";
import UssdMessenger from './UssdMessenger.js'
import Paginator from '../../Paginator/Paginator'
import SearchFilter from '../../SearchFilter/SearchFilter'

import { localTimeFormat, convertTimeSent, toLocalTime } from '../../../utilities/timeHelper.js'
import { AppContext } from '../../../AppContext.js';

export default function SimcardUssdList(props) {
    const { setGlobalError } = React.useContext(AppContext);
    const [busy, setBusy] = React.useState([]);
    const [data, setData] = React.useState(null);
    const [messengerOption, setMessengerOption] = React.useState(null);
    const [selectedUssd, setSelectedUssd] = React.useState(null);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [searchFilter, setSearchFilter] = React.useState("");

    const updateUnitSmsList = (silent) => {
        const assetId = props?.selectedEntry?.assetId;
        const interval = timeSpanFromHours(24 * 90);
        const filter = {
            pageNumber: pageNumber,
            search: searchFilter?.value ?? ""
        }

        if (!silent) {
            setBusy(true)
        }

        fetchUssd((e) => {
            if (!silent) {
                setBusy(false)
            }

            if (e.error) {
                setGlobalError(e.error)
                return;
            }

            setData(e.data)
        }, filter);
    }

    React.useEffect(() => {
        updateUnitSmsList();
    }, [props.selectedEntry]);

    React.useEffect(() => {
        updateUnitSmsList(true);
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateUnitSmsList(true);
        }
    }, [searchFilter]);

    if (busy) return null;
    const ussds = data?.data;
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                    <table style={{ width: "100%", fontSize: "14px" }}>
                        <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                            <tr>
                                <th style={{ textAlign: "left", }}>Created</th>
                                <th style={{ textAlign: "left", }}>Asset</th>
                                <th style={{ textAlign: "left" }}>Header</th>
                                <th style={{ textAlign: "left" }}>Body</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ussds &&
                                ussds.map((ussd, index) => {
                                    const finalUssd = ussd;
                                    const isSelected = (selectedUssd && finalUssd.id === selectedUssd?.id);
                                    const time = localTimeFormat(toLocalTime(finalUssd.createdAt));
                                    return (
                                        <tr key={index}
                                            style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                            onClick={() => {
                                                setSelectedUssd(finalUssd)
                                            }}>
                                            <td style={{ textAlign: "left" }}>{time}</td>
                                            <td style={{ textAlign: "left" }}>{finalUssd.asset}</td>
                                            <td style={{ textAlign: "left" }}>{finalUssd.header}</td>
                                            <td style={{ textAlign: "left" }}>{truncateWithEllipses(finalUssd.body, 50)}</td>
                                        </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", columnGap: '20px', height: "25px", padding: "3px" }}>
                <button
                    onClick={() => {
                        setMessengerOption({
                            open: true,
                            selectedUssd: selectedUssd

                        })
                    }}>
                    USSD Messenger
                </button>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
                <Paginator
                    changeIndicator={data}
                    initialNumber={pageNumber}
                    pageSize={data?.pageSize}
                    pageCount={data?.pageCount}
                    count={data?.count}
                    onPageSelected={(page) => {
                        console.log(page)
                        setPageNumber(page.number)
                    }} />
            </div>
            {messengerOption?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <UssdMessenger onClose={() => {
                    setMessengerOption(null)
                }} {...messengerOption} />
            </div>}
        </div>
    );
}
