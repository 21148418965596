import React from 'react'
import { localTimeFormat } from '../../../utilities/timeHelper'
import verifyContact from '../../../calls/administration/contacts/verifyContact';
import deleteContact from '../../../calls/administration/contacts/deleteContact';
import { showLoading } from 'react-global-loading';
import ConfirmBox from "../ConfirmBox"
import fetchContacts from "../../../calls/administration/contacts/fetchContacts";
import fetchFakeContacts from '../../../calls/administration/contacts/fetchFakeContacts';
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors'
import ContactCreation from "./ContactCreation";
import { AppContext } from '../../../AppContext';
import SearchFilter from '../../SearchFilter/SearchFilter';
import Paginator from '../../Paginator/Paginator';

function ContactsListView(props) {
    const { setGlobalError } = React.useContext(AppContext);
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [smsVerificationData, setSmsVerificationData] = React.useState(null);
    const [emailVerification, setEmailVerification] = React.useState(null);
    const [contactDeleteData, setContactDeleteData] = React.useState(null);
    const [contacts, setContacts] = React.useState([]);
    const [busy, setBusy] = React.useState(true);
    const [contactCreation, setContactCreation] = React.useState(null);

    const [data, setData] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState(null);

    const updateContacts = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        const filter = {
            pageNumber: pageNumber.value,
            search: searchFilter?.value ?? "",
            pageSize: 32
        }

        fetchContacts((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                setGlobalError(e.error)
                return;
            }
            setData(e?.data)
            setContacts(e?.data?.data)
        }, filter);
    }

    React.useEffect(() => {
        updateContacts(false);
    }, []);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const page = { ...pageNumber }
            page.updated = false;
            setPageNumber(page)
            updateContacts(true);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateContacts(true);
        }
    }, [searchFilter]);


    if (busy) {
        return null;
    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
            <div id='topbar' n style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                <div style={{ flex: 1 }}></div>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
            </div>
            <div style={{ width: "100%", flexFlow: "column", display: "flex", flex: 1 }}>
                <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                    <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                        <table style={{ width: "100%", fontSize: "14px" }}>
                            <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                <tr>
                                    <th style={{ textAlign: "left", }}>Name</th>
                                    <th style={{ textAlign: "left", }}>email</th>
                                    <th style={{ textAlign: "left" }}>number</th>
                                    <th style={{ textAlign: "left" }}>Created</th>
                                    <th style={{ textAlign: "left" }}>Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    contacts &&
                                    contacts.map((contact, index) => {
                                        const finalContact = contact;
                                        const isSelected = (selectedContact && finalContact.id === selectedContact?.id);
                                        return (
                                            <tr key={index}
                                                style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                                onClick={() => {
                                                    setSelectedContact(finalContact)
                                                }}>
                                                <td style={{ textAlign: "left" }}>{finalContact.name}</td>
                                                <td style={{ textAlign: "left" }}>{finalContact.email}</td>
                                                <td style={{ textAlign: "left" }}>{finalContact.number}</td>
                                                <td style={{ textAlign: "left" }}>{localTimeFormat(finalContact.createdAt)}</td>
                                                <td style={{ textAlign: "left" }}>{localTimeFormat(finalContact.updatedAt)}</td>
                                            </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    Boolean(data?.pageCount && data?.pageCount > 1) &&
                    <div style={{ alignItems: "end", display: "flex", justifyContent: "end", marginRight: "20px" }}>
                        <Paginator
                            changeIndicator={data}
                            initialNumber={pageNumber.value}
                            pageSize={data?.pageSize}
                            pageCount={data?.pageCount}
                            count={data?.count}
                            onPageSelected={(page) => {
                                console.log(page)
                                setPageNumber({
                                    value: page.number,
                                    updated: true
                                })
                            }} />
                    </div>
                }
            </div>
            <div style={{ height: "30px", display: "flex", padding: "2px" }}>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    onClick={() => {
                        setContactCreation({
                            open: true
                        })
                    }}>
                    Create
                </button>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    disabled={!selectedContact}
                    onClick={() => {
                        setContactCreation({
                            open: true,
                            editContact: selectedContact
                        })
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    disabled={!selectedContact}
                    onClick={() => {
                        if (selectedContact) {
                            setSmsVerificationData({
                                open: true,
                                message: `Vous etes sur le point de verifier ${selectedContact.name} par SMS`,
                                title: "verfication SMS",
                                contact: selectedContact
                            });
                        }
                    }}>
                    Verify number
                </button>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    disabled={!selectedContact}
                    onClick={() => {
                        if (selectedContact) {
                            setEmailVerification({
                                open: true,
                                message: `Vous etes sur le point de verifier ${selectedContact.name} par Email`,
                                title: "verfication SMS",
                                contact: selectedContact
                            });
                        }
                    }}>
                    Verify Email
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedContact}
                    onClick={() => {
                        if (selectedContact) {
                            setContactDeleteData({
                                open: true,
                                message: `Vous etes sur le point de supprimer le contact ${selectedContact.name}`,
                                title: "Suppression contact",
                                contact: selectedContact
                            });
                        }
                    }}>
                    Delete
                </button>
            </div>
            {
                contactCreation?.open &&
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ContactCreation
                        options={contactCreation}
                        open={contactCreation?.open}
                        onContactedited={() => {
                            setContactCreation(false)
                            updateContacts(false);
                        }}
                        onContactCreated={() => {
                            setContactCreation(false)
                            updateContacts(false);
                        }}
                        onCancel={() => {
                            setContactCreation(false)
                        }} />
                </div>
            }

            {
                emailVerification?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ConfirmBox
                        onCancel={() => {
                            setEmailVerification(null)
                        }}
                        onConfirm={(contact) => {
                            const data = {
                                contactId: contact.id,
                                number: false,
                                email: true
                            };

                            showLoading(true)
                            verifyContact(data, (e) => {
                                setEmailVerification(null)
                                showLoading(false)
                                if (e.error)
                                    setGlobalError(e.error)
                            });
                        }}
                        title={emailVerification?.title}
                        data={emailVerification?.contact}
                        open={emailVerification?.open}
                        message={emailVerification?.message} />
                </div>
            }
            {
                smsVerificationData?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ConfirmBox
                        onCancel={() => {
                            setSmsVerificationData(null)
                        }}
                        onConfirm={(contact) => {
                            const data = {
                                contactId: contact.id,
                                number: true,
                                email: false
                            };

                            showLoading(true)
                            verifyContact(data, (e) => {
                                setSmsVerificationData(null)
                                showLoading(false)
                                if (e.error)
                                    setGlobalError(e.error)
                            });
                        }}
                        title={smsVerificationData?.title}
                        data={smsVerificationData?.contact}
                        open={smsVerificationData?.open}
                        message={smsVerificationData?.message} />
                </div>
            }
            {
                contactDeleteData?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ConfirmBox
                        onCancel={() => {
                            setContactDeleteData(null)
                        }}
                        onConfirm={(contact) => {
                            const data = {
                                contactId: contact.id,
                                number: contact.number,

                            };

                            showLoading(true)
                            deleteContact(data, (e) => {
                                setContactDeleteData(null)
                                showLoading(false)
                                if (e.error)
                                    setGlobalError(e.error)
                                updateContacts();
                            });
                        }}
                        title={contactDeleteData?.title}
                        data={contactDeleteData?.contact}
                        open={contactDeleteData?.open}
                        message={contactDeleteData?.message} />
                </div>
            }
        </div>);
}

export default ContactsListView;