
import React from 'react'
import { DropdownList } from 'react-widgets';
import { showLoading } from 'react-global-loading';

import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";
import { localTimeFormat } from '../../../utilities/timeHelper'
import { createConfig, deleteConfig, updateConfig, pushConfig } from "../../../calls/administration/configs/createConfig";
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors'

const types =
    [
        { name: "system", value: 0 },
        { name: "user", value: 1 }
    ];
function ConfigCreation(props) {
    const [feature, setFeature] = React.useState("");
    const [config, setConfig] = React.useState("");
    const [value, setValue] = React.useState("");
    const [type, setType] = React.useState(types[0]);
    const [error, setError] = React.useState("");

    const isUser = (type === types[1]);
    React.useEffect(() => {
        if (!props.open) {
            setError("")
            setFeature("")
            return;
        }

        const config = props?.editData?.config;
        if (config) {
            setFeature(config.featureIndex)
            setConfig(config.configIndex)
            setValue(config.value)
            setType(types[config.type])
            //setError("")
            //setFeature(config.feature)
        }

        return () => {
            setError("")
            setFeature("")
            if (props.ondispose) {
                props.ondispose()
            }
        }


    }, [props.open]);
    return <dialog open={props.open}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "400px"
        }}>
        <div>
            <div style={{ textAlign: "right" }}>
                Config creation
            </div>
            <hr />

            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_type">Feature</label>
                <DropdownList
                    readOnly={Boolean(props?.editData?.config)}
                    style={{ display: "block", width: "100%" }}
                    id="config_creation_type"
                    defaultValue={types[1]}
                    textField='name'
                    filter={false}
                    data={types}
                    onChange={(item) => {
                        setType(item)
                    }}
                    value={type} />
            </div>
            {
                isUser &&
                <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                    <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_feature">Feature</label>
                    <input style={{ display: "block", width: "100%" }} type="number" id="config_creation_feature" placeholder="feature index" value={feature}
                        autoComplete="off"
                        readOnly={props?.editData?.config}
                        onChange={(e) => {
                            setFeature(e.target.value)
                        }} />
                </div>
            }

            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_config">Config</label>
                <input style={{ display: "block", width: "100%" }} type="number" id="config_creation_config" placeholder="config index" value={config}
                    autoComplete="off"
                    readOnly={props?.editData?.config}
                    onChange={(e) => {
                        setConfig(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="config_creation_value">Value</label>
                <textarea style={{ display: "block", width: "100%", resize: "none" }} maxLength={1000} type="t" id="config_creation_value" placeholder="config index" value={value}
                    autoComplete="off"
                    onChange={(e) => {
                        setValue(e.target.value)
                    }} />
            </div>

            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {


                    const validConfig = parseInt(config);
                    let validFeature = parseInt(feature);

                    if (type === types[0]) {
                        validFeature = 0;
                    }

                    const edit = props?.editData?.config;
                    const data = {
                        type: type,
                        feature: validFeature,
                        config: validConfig,
                        value: value,
                        unitId: props?.relatedUnit?.id
                    };

                    if (edit) {
                        data.configId = edit.id;
                        showLoading(true)
                        updateConfig(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onConfigEdited) {
                                return;
                            }

                            props?.onConfigEdited(e.data)
                        });
                    }
                    else {
                        showLoading(true)
                        createConfig(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onConfigCreated) {
                                return;
                            }

                            props?.onConfigCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export default ConfigCreation;