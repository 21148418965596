import React from 'react'
import { useContext } from 'react';
import { AppContext } from "../../AppContext";
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { checkIfEmptyOrWhiteSpace } from '../../utilities/stringHelper';
import { useNavigate } from "react-router-dom";
import TopBarComponenent from '../TopBarComponenent';
import { changeUserPassword } from '../../calls/administration/users/createUser';
import './PasswordChange.css';

function PasswordChange(props) {
    const navigate = useNavigate();
    const { busy, setBusy } = useContext(AppContext);
    const { appThemeColor } = React.useContext(AppContext);
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [currentPasswordError, setCurrentPasswordError] = React.useState("");

    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordError, setNewPasswordError] = React.useState("");

    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

    const [error, setError] = React.useState("");
    const mobile = isMobile;
    const title = "Il vous est requis de changer de mot de passe";
    const titleFontSize = mobile ? "12px" : "16px";
    const barHeight = mobile ? "22px" : "35px";

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, [props.ready]);

    const onBackAction = (e) => {
        e.preventDefault();
        if (props.onCancel) {
            props.onCancel();
        }

        if (props.cancelRoute) {
            navigate(props.cancelRoute)
        }
    };

    React.useEffect(() => {
        //window.history.pushState(null, null, document.URL);
        window.addEventListener("popstate", onBackAction);
        return () => window.removeEventListener("popstate", onBackAction);
    }, []);

    const verifySamePassword = () => {
        if (newPassword === confirmPassword)
            return true;
        setNewPasswordError('New password do not match with confirm password')
        setConfirmPasswordError('Confirm password do not match with new password')
    }

    const clearErrors = () => {
        setNewPasswordError(null)
        setConfirmPasswordError(null)
        setCurrentPasswordError(null)
    }

    function confirmChange(e) {
        e.preventDefault();
        clearErrors()
        if (!verifySamePassword())
            return;
        const data = {
            currentPassword: currentPassword,
            newPassword: newPassword
        };

        setBusy(true)
        changeUserPassword(data, (e) => {
            setBusy(false)
            if (e.error) {
                setError(e.error)
                return;
            }

            if (props.onSuccess) {
                props.onSuccess();
            }

            if (props.successRoute) {
                navigate(props.successRoute)
            }
        });
    }

    if (!props.ready)
        return null;

    const canConfirm = !checkIfEmptyOrWhiteSpace(confirmPassword) && !checkIfEmptyOrWhiteSpace(newPassword);
    return (
        <div className='full-screen'>
            <div className={isMobile ? 'top-bar-div-mobile' : 'top-bar-div'} style={{ backgroundColor: appThemeColor }}>
                <TopBarComponenent showInfo={true} showScreenControl={true} showNoFullScreen={true} />
            </div>
            <div className='centre-div'>
                <form onSubmit={confirmChange}>
                    <div className='password-box'>
                        <div className='title-class' style={{ fontSize: titleFontSize }}>{title}</div>
                        <hr />
                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "140px" }} htmlFor="current_password">New Password</label>
                            <input type="password"
                                autoComplete='off'
                                id="current_password"
                                placeholder="Mot de passe" value={currentPassword} style={{ width: "100%" }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        if (!busy && canConfirm) {
                                            //loginUser(username, password);
                                        }
                                    }
                                }}
                                onChange={(e) => {
                                    setError(null)
                                    setCurrentPassword(e.target.value)
                                }} />
                        </div>
                        {checkIfEmptyOrWhiteSpace(currentPasswordError) ?
                            null : (<div className='error-text'>{currentPasswordError}</div>)}

                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "140px" }} htmlFor="new_password">New Password</label>
                            <input type="password"
                                name="new_password"
                                id="new_password"
                                autoComplete='off'
                                placeholder="Mot de passe" value={newPassword} style={{ width: "100%" }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        if (!busy && canConfirm) {
                                            //loginUser(username, password);
                                        }
                                    }
                                }}
                                onChange={(e) => {
                                    setError(null)
                                    setNewPassword(e.target.value)
                                }} />
                        </div>
                        {checkIfEmptyOrWhiteSpace(newPasswordError) ? null : (<div className='error-text'>{newPasswordError}</div>)}
                        <div style={{ display: "flex", margin: "5px 0px" }}>
                            <label style={{ display: "block", width: "140px" }} htmlFor="confirm_password">Confirm Password</label>
                            <input
                                type="password"
                                id="confirm_password"
                                autoComplete='off'
                                placeholder="Confirmer Mot de passe" value={confirmPassword} style={{ width: "100%" }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        if (!busy && canConfirm) {
                                            //loginUser(username, password);
                                        }
                                    }
                                }}
                                onChange={(e) => {
                                    setError(null)
                                    setConfirmPassword(e.target.value)
                                }} />
                        </div>
                        {checkIfEmptyOrWhiteSpace(confirmPasswordError) ?
                            null : (<div className='error-text'> {confirmPasswordError}</div>)
                        }

                        <div style={{ textAlign: "left", display: "flex", flexDirection: "column", margin: "10px 0px 0px 0px" }}>
                            {checkIfEmptyOrWhiteSpace(error) ?
                                null : (<div className='error-text'> {error}</div>)}
                            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", columnGap: "20px" }}>
                                <button type="button" className='confirm-button' onClick={() => {
                                    if (props.onCancel) {
                                        props.onCancel();
                                    }

                                    if (props.cancelRoute) {
                                        navigate(props.cancelRoute)
                                    }
                                }}>Cancel</button>
                                <button type="submit" className='confirm-button' disabled={!canConfirm}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div style={{ height: barHeight }}></div>
        </div>);
}

export default PasswordChange;