import { appConfig } from '../configurations/appConfig'
import urlJoin from 'url-join';
const buildAssetColorIconUrl = (assetTypeName, color) => {
    if (color)
        color = color.replace("#", "")
    const method = `AssetTypes/download-icon/${assetTypeName}/${color}.svg`;
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    return urlJoin(server, serverBasePath, method);
}

export { buildAssetColorIconUrl }