import React from 'react'
import { AppContext } from '../../../AppContext';
import { localTimeFormat, toUctTime, toLocalTime } from '../../../utilities/timeHelper'
import { showLoading } from 'react-global-loading';
import fetchActivities from "../../../calls/administration/fetchActivities";
import { IconContext } from "react-icons";
import { GrUpdate } from "react-icons/gr";
import ActivityTimeRange from './ActivityTimeRange'
import "./ActivitiesListView.css"

function ActivitiesListView(props) {
    const { setGlobalError } = React.useContext(AppContext);
    const [intervalData, setIntervalData] = React.useState(null);
    const [selectedActivity, setSelectedActivity] = React.useState(null);
    const [data, setData] = React.useState(null);
    const divRef = React.useRef(null);
    const updateActivities = (onComplete, silent = true) => {
        if (!silent) {
            //setBusy(true);
            showLoading(true);
        }

        const filter = {
            start: intervalData?.start ?? null,
            end: intervalData?.end ?? null
        }

        fetchActivities((e) => {
            if (!silent) {
                //setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                setGlobalError(e.error)
                if (onComplete) onComplete(false);
                return;
            }

            setData(e.data)
            //setActivities(e.data)
            if (onComplete) onComplete(true);
        }, filter);
    }

    React.useEffect(() => {
        updateActivities(null, false)

        const handleClickOutside = event => {
            event.preventDefault()
            if (divRef.current && !divRef.current.contains(event.target)) {
                if (event.target.nodeName.toLowerCase() === 'button') return;
                setSelectedActivity(null)
            }
        };

        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);
    const activities = data?.data;
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", padding: "0px 5px" }} >
                    <ActivityTimeRange
                        onRangeInterval={(e) => {
                            setIntervalData(e)
                        }} />
                    <button style={{ margin: "0px 0px 0px 10px" }}
                        onClick={() => {
                            updateActivities(null, false)
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <IconContext.Provider value={{ size: 14 }}>
                                <GrUpdate />
                            </IconContext.Provider>
                        </div>
                    </button>
                </div>
            </div>
            <div ref={divRef} style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                    <table style={{ width: "100%", fontSize: "12px" }}>
                        <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                            <tr>
                                <th style={{ textAlign: "left", }}>Date</th>
                                <th style={{ textAlign: "left", }}>Message</th>
                                <th style={{ textAlign: "left" }}>User</th>
                                <th style={{ textAlign: "left" }}>Machine</th>
                                <th style={{ textAlign: "left" }}>Ip</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                activities &&
                                activities.map((contact, index) => {
                                    const finalActivity = contact;
                                    const isSelected = (selectedActivity && finalActivity.id === selectedActivity?.id);
                                    return (
                                        <tr key={index}
                                            style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                            onClick={() => {
                                                setSelectedActivity(finalActivity)
                                            }}>
                                            <td style={{ textAlign: "left" }}>{localTimeFormat(finalActivity.date)}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.message}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.user}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.machineName}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.ipAddress}</td>
                                        </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{ height: "20px", width: "100%" }}>

            </div>
        </div>);
};

export default ActivitiesListView;