function checkIfEmptyOrWhiteSpace(str) {
    if (!str) return true
    if (str?.trim()?.length === 0) {
        return true;
    } else {
        return false;
    }
}

function checkIfEmpty(str) {
    if (str) {
        return true;
    } else {
        return false;
    }
}

function truncateWithEllipses(text, max) {
    if (!text) return ""
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

function truncate(str, n, useWordBoundary) {
    if (str.length <= n) { return str; }
    const subString = str.slice(0, n - 1); // the original check
    return (useWordBoundary
        ? subString.slice(0, subString.lastIndexOf(" "))
        : subString) + " ...";
};

export { checkIfEmptyOrWhiteSpace, checkIfEmpty, truncate, truncateWithEllipses }