import React from 'react'
import DatePicker from "react-widgets/DatePicker";
import DropdownList from "react-widgets/DropdownList";
import { isMobile } from 'react-device-detect';
import {
    getTodayStartDate, getTodayEndDate,
    getYesterdayStartDate, getYesterdayEndDate
} from '../../../utilities/dateHelper'
import "./ActivitiesListView.css"

const today = "Aujourd'hui";
const yesterday = "Hier";
const custom = "Personnalisé";
const latest = "Recent"
const intervalRanges = [latest, today, yesterday, custom];
function ActivityTimeRange(props) {
    const [rangeData, setRangeData] = React.useState({});
    const [startPickerOpen, setStartPickerOpen] = React.useState(undefined);
    const [endPickerOpen, setEndPickerOpen] = React.useState(undefined);
    const [intervalRange, setIntervalRange] = React.useState(intervalRanges[0]);
    const titleFontSize = isMobile ? 10 : 14;

    const startPickerInputProps = {
        editing: false,
        readOnly: true,
        onClick: (e) => {
            setStartPickerOpen(true);
        }
    };

    const endPickerInputProps = {
        editing: false,
        readOnly: true,
        onClick: (e) => {
            setEndPickerOpen(true);
        }
    };

    React.useEffect(() => {
        if (props.onRangeInterval) {
            const data = { ...rangeData }
            props.onRangeInterval(data)
        }
    }, [rangeData]);

    React.useEffect(() => {
        if (intervalRange === custom) {
            const data = { ...rangeData }
            data.option = 2;
            setRangeData(data)
        }
        else if (intervalRange === yesterday) {
            const data = { ...rangeData }
            data.start = getYesterdayStartDate();
            data.end = getYesterdayEndDate();
            data.option = 1;

            setRangeData(data)
        }
        else if (intervalRange === today) {
            const data = { ...rangeData }
            data.start = getTodayStartDate();
            data.end = getTodayEndDate();
            data.option = 0;
            setRangeData(data)
        }
        else {
            const data = { ...rangeData }
            data.start = null;
            data.end = null;
            data.option = 0;
            setRangeData(data)
        }
    }, [intervalRange]);

    const isCustom = intervalRange === custom;
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {isCustom && <div style={{ display: "flex", flexDirection: "row", margin: "0px 5px" }}>
                <div style={{ display: "flex", margin: "2px 0px", width: "150px", alignItems: "baseline" }}>
                    <label htmlFor="fromDate" style={{ textAlign: "left", fontSize: titleFontSize, margin: "0px 4px" }}>
                        F
                    </label>
                    <DatePicker
                        onChange={(e) => {
                            const data = { ...rangeData }
                            data.option = 2;
                            data.start = e;
                            setRangeData(data)
                        }}
                        id="fromDate"
                        dropUp={props.dropUp}
                        value={rangeData?.start}
                        open={startPickerOpen}
                        filter={false}
                        inputProps={startPickerInputProps}
                        defaultValue={rangeData?.start}
                        max={rangeData?.end}
                        valueFormat={{ dateStyle: "medium", hourCycle: 'h23' }}
                        //readOnly={disabled}
                        onBlur={() => { setStartPickerOpen(undefined) }}
                        onSelect={() => { setStartPickerOpen(undefined) }} />
                </div>
                <div style={{ display: "flex", margin: "2px 0px", width: "150px", alignItems: "baseline" }}>
                    <label htmlFor="toDate" style={{ textAlign: "left", fontSize: titleFontSize, margin: "0px 4px" }}>T</label>
                    <DatePicker
                        onChange={(e) => {
                            const data = { ...rangeData }
                            data.option = 2;
                            data.end = e;
                            setRangeData(data)
                        }}
                        id="toDate"
                        open={endPickerOpen}
                        dropUp={props.dropUp}
                        value={rangeData?.end}
                        min={rangeData?.start}
                        inputProps={endPickerInputProps}
                        filter={false}
                        defaultValue={rangeData?.end}
                        valueFormat={{ dateStyle: "medium", hourCycle: 'h23' }}
                        onBlur={() => { setEndPickerOpen(undefined) }}
                        onSelect={() => { setEndPickerOpen(undefined) }} />
                </div>
            </div>}
            <DropdownList
                style={{ display: "flex", width: "120px" }}
                defaultValue={intervalRanges[0]}
                data={intervalRanges}
                autoFocus={false}
                filter={false}
                onChange={(e) => {
                    setIntervalRange(e)
                }}
                value={intervalRange}
            />
        </div>);
}

export default ActivityTimeRange;