import { lang } from "../language/lang"
const period1min = 1000 * 60;
const period1hour = period1min * 60;
const period1day = period1hour * 24;
const period30day = period1day * 30;
const period1year = period1day * 365;

const convertTimeSent = (date) => {
    var now = new Date()
    var nowutcms = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    var datems = new Date(date);
    var period = nowutcms - datems;
    if (period < period1min) {
        const seconds = Math.floor(period / 1000);
        return lang.language.secondsAgo?.toString().format(seconds, seconds > 1 ? 's' : '');
    }
    else if (period < period1hour) {
        const minutes = Math.floor(period / period1min);
        return lang.language.minutesAgo?.toString().format(minutes, minutes > 1 ? 's' : '');
    }
    else if (period < period1day) {
        const hours = Math.floor(period / period1hour);
        return lang.language.hoursAgo?.toString().format(hours, hours > 1 ? 's' : '');
    }
    else if (period < period30day) {
        const days = Math.floor(period / period1day);
        return lang.language.daysAgo?.toString().format(days, days > 1 ? 's' : '');
    }
    else if (period < period1year) {
        const months = Math.floor(period / period30day);
        return lang.language.monthsAgo?.toString().format(months, months > 1 ? 's' : '');
    }

    const years = Math.floor(period / period1year);
    return lang.language.yearsAgo?.toString().format(years, years > 1 ? 's' : '');
};

const nowUct = () => {
    var now = new Date()
    var nowutcms = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    return new Date(nowutcms)
}

const toUctTime = (date) => {
    const newdDate = new Date(date)
    var newdDateUtc = newdDate.getTime() + newdDate.getTimezoneOffset() * 60 * 1000;
    return new Date(newdDateUtc)
}

const toLocalTime = (date) => {
    const newdDate = new Date(date)
    var newdDateUtc = newdDate.getTime() - newdDate.getTimezoneOffset() * 60 * 1000;
    return new Date(newdDateUtc)
}

const defaultFormat = {
    weekday: 'short', year: 'numeric', month: 'short',
    day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
};
const localTimeFormat = (date, options) => {
    //var now = new Date()
    //var nowutcms = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    var datems = new Date(date);

    const o = options ? options : defaultFormat;

    return datems.toLocaleString(undefined, o);
};

const toLocalTimeFormatted = (date, options) => {
    if (!date) return "__";
    return localTimeFormat(toLocalTime(date), options);
}

const addDays = (date, count) => {
    var newDate = new Date(date);
    newDate.setDate(date.getDate() + count);
    return newDate;
}

export { convertTimeSent, localTimeFormat, nowUct, toUctTime, toLocalTime, addDays, toLocalTimeFormatted }