import React from 'react'
import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";
import { createUser, updateUser } from "../../../calls/administration/users/createUser";
import DropdownList from "react-widgets/DropdownList";
import { showLoading } from 'react-global-loading';
import fetchAccounts from "../../../calls/administration/accounts/fetchAccounts";

function UserCreation(props) {
    const [username, setUsername] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [telephone, setTelephone] = React.useState("");
    const [error, setError] = React.useState("");
    const [successText, setSuccessText] = React.useState("");

    const [accounts, setAccounts] = React.useState([]);
    const [selectedAccount, setSelectedAccount] = React.useState(null);
    const [accountSearchTerm, setAccountSearchTerm] = React.useState('');

    const [ready, setReady] = React.useState(false);
    const editUser = props.options?.editUser;

    const updateAccounts = (onResult, filter) => {
        fetchAccounts((e) => {
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setAccounts(e?.data?.data)
            if (onResult) onResult(true, e?.data?.data);
        }, filter);
    }

    const initialise = () => {
        const filter = {
            count: 50,
            additionalIds: editUser?.accountId ? [editUser.accountId] : null
        };
        showLoading(true)
        updateAccounts((result, accounts) => {
            showLoading(false)
            if (editUser) {
                setUsername(editUser.userName)
                setTelephone(editUser.phoneNumber)
                setEmail(editUser.email);
                if (accounts) {
                    const selected = accounts.find(x => x.id === editUser.accountId);
                    setSelectedAccount(selected)
                }
            }

            setReady(true);
        }, filter)
    }

    const onSearch = (e) => {
        setAccountSearchTerm(e);

        const filter = {
            count: 50,
            searchTerm: e,
            additionalIds: editUser ? [editUser.accountId] : null
        };

        updateAccounts(() => {
        }, filter);
    }

    React.useEffect(() => {
        initialise();
        return () => {
            setUsername('')
            setTelephone('')
            setEmail('');
            setSelectedAccount(null)
        }

    }, []);

    if (!ready) return null;
    return <dialog open={props.options?.open}
        style={{
            //position:"absolute",
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
        }}>

        <div>
            <div style={{ textAlign: "right" }}>
                {editUser ? `User edition (${editUser.id})` : "User creation"}
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "140px", textAlign: "left" }} htmlFor="user_creation_username">Nom utilisateur</label>
                <input style={{ display: "block", width: "100%" }}
                    type="text" id="user_creation_username"
                    autoComplete='off'
                    placeholder="Nom de l'utilisateur" value={username}
                    onChange={(e) => {
                        setUsername(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "140px", textAlign: "left" }} htmlFor="user_creation_email">Email</label>
                <input style={{ display: "block", width: "100%" }}
                    type="email" id="user_creation_email"
                    placeholder="Email"
                    value={email}
                    autoComplete='off'
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "140px", textAlign: "left" }} htmlFor="user_creation_tel">Telephone</label>
                <input style={{ display: "block", width: "100%" }}
                    type="tel" id="user_creation_tel"
                    autoComplete='off'
                    placeholder="Telephone" value={telephone}
                    onChange={(e) => {
                        setTelephone(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "140px", textAlign: "left" }} htmlFor="asset_creation_account">Account</label>
                <span style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
                    <DropdownList
                        id="asset_creation_account"
                        readOnly={editUser}
                        style={{ display: "block", width: "100%" }}
                        data={accounts}
                        dataKey='id'
                        filter='contains'
                        textField='name'
                        value={selectedAccount}
                        searchTerm={accountSearchTerm}
                        //disabled={!accountData?.accounts || !ready}
                        onSearch={onSearch}
                        onChange={(value) => {
                            setSelectedAccount(value)
                        }} />
                    {
                        !editUser && selectedAccount && <button onClick={() => {
                            setSelectedAccount(null)
                        }}
                            style={{ display: "block", height: "20px", marginLeft: "5px", borderColor: "lightgray" }}>x</button>
                    }
                </span>
            </div>
            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                {error}
            </div>)}
            {checkIfEmptyOrWhiteSpace(successText) ? null : (<div style={{ display: "flex", color: "green", fontWeight: "bold", fontSize: "8" }}>
                {successText}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    setSuccessText("")
                    if (checkIfEmptyOrWhiteSpace(username)) {
                        setError("Username cannot be empty")
                        return;
                    }

                    if (checkIfEmptyOrWhiteSpace(email) && checkIfEmptyOrWhiteSpace(telephone)) {
                        setError("Email and Telephone cannot be both empty")
                        return;
                    }

                    if (editUser) {
                        const data = {
                            email: email,
                            userName: username,
                            telephone: telephone,
                            accountId: selectedAccount?.id ?? -1,
                            userId: editUser.id
                        };

                        showLoading(true)
                        updateUser(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (e?.data?.error) {
                                setError(e?.data?.error)
                                return;
                            }

                            if (!props?.onUserEdited) return;
                            setSuccessText(`User ${data.userName} was editted`)
                            props?.onUserEdited(data)
                        });
                    }
                    else {
                        const data = {
                            email: email,
                            userName: username,
                            telephone: telephone,
                            accountId: selectedAccount?.id ?? -1
                        };

                        showLoading(true)
                        createUser(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (e?.data?.error) {
                                setError(e?.data?.error)
                                return;
                            }

                            if (!props?.onUserCreated) return;
                            setSuccessText(`New user ${e?.data?.user?.userName} created, password generated : ${e.data?.password}`)
                            props?.onUserCreated(e.data)
                        });
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export default UserCreation;