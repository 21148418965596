import React from 'react'
import { FaFilter } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { checkIfEmptyOrWhiteSpace } from "../../utilities/stringHelper";
import './SearchFilter.css'

function SearchFilter(props) {
    const [filter, setFilter] = React.useState("");
    React.useEffect(() => {
        if (props.initialValue) {
            setFilter({
                value: props.initialValue,
                updated: false
            });
        }
    }, []);

    React.useEffect(() => {
        if (filter?.updated) {
            const newFilter = { ...filter }
            newFilter.updated = false;
            setFilter(newFilter)
            if (props?.onFilterChange)
                props?.onFilterChange(newFilter.value)
        }
    }, [filter]);

    const isEmpty = checkIfEmptyOrWhiteSpace(filter?.value);
    const borderStyle = isEmpty ? "solid gray 2px" : "solid red 2px";
    return (
        <div style={{
            display: "flex", position: "relative", alignItems: "center", width: "250px",
            borderRadius: "3px",
            border: borderStyle
        }}>
            <div style={{ top: 0, left: 0, position: "absolute", width: "100%", display: "flex", height: "100%" }}>
                <input
                    className='search-filter'
                    style={{ width: "100%", border: "none", outline: "none" }}
                    type="text"
                    placeholder='Filter here'
                    value={filter?.value}
                    onChange={(e) => {
                        setFilter({
                            value: e.target.value,
                            updated: true
                        })
                    }}>
                </input >
            </div>
            <div
                style={{
                    position: "absolute",
                    display: "flex",
                    alignSelf: "end",
                    height: "100%",
                    width: "100%",
                    pointerEvents: "none",
                    justifyContent: "end",
                }}>
                {
                    !isEmpty &&
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px", pointerEvents: "auto" }}
                        onClick={() => {
                            setFilter({
                                value: "",
                                updated: true
                            })
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <MdClear size="16px" style={{ color: "red" }}></MdClear>
                        </div>
                    </button>
                }
                {
                    isEmpty &&
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px", pointerEvents: "auto" }}
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <div style={{ alignItems: "center", justifyContent: "center" }}>
                            <FaFilter size="12px" style={{ color: "gray" }}></FaFilter>
                        </div>
                    </button>
                }
            </div>
        </div>
    );
}

export default SearchFilter