import React, { useState, useEffect } from "react";
import { forwardRef, useImperativeHandle } from 'react';
import { hexToRgbaWithAlpha } from "../../utilities/convertColors";

function Notificator(props, ref) {
    const [notifications, setNotifications] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const pushNotification = ({ notification, color, lifetime = 2 } = {}) => {

        setNotifications(prev => {
            const list = [...prev];
            list.push({ notification, color, lifetime });
            return list;
        });
    }

    const processNotifyChange = (notify) => {
        const message = notify.notification;
        if (!message) {
            const data = {
                notification: notify,
                color: "red",
                lifetime: 2
            }

            pushNotification(data)
        }
        else {
            const data = {
                notification: notify.notification,
                color: notify.color ?? "red",
                lifetime: notify.lifetime ?? 2
            }

            pushNotification(data)
        }
    }

    const updateNotification = () => {
        if (!refresh) return;
        setRefresh(false)
        const prev = notifications
        if (prev.length === 0)
            return prev;

        const list = [...prev];

        let allTimeout = true;
        for (var notification of list) {
            notification.lifetime--;
            if (notification.lifetime > 0) {
                allTimeout = false;
            }
        }

        if (allTimeout) {
            list.length = 0;
            if (props.onComplete)
                props.onComplete()
        }

        setNotifications(list)
    }

    useImperativeHandle(ref, () => ({
        pushNotification: pushNotification,
    }));

    useEffect(() => {
        const timer = setInterval(() => {
            setRefresh(true)
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        const notify = props.notify;
        if (notify) {
            processNotifyChange(notify)
        }

    }, [props.notify]);

    useEffect(() => {
        updateNotification();

    }, [refresh]);



    if (notifications.length === 0) {
        return null;
    }

    return (
        <div style={{
            display: "flex",
            backgroundColor: hexToRgbaWithAlpha("black", 0.1),
            flexDirection: "column",
            padding: "5px",
            height: "100%"
        }}>
            {notifications.map(item => (
                item.lifetime > 0 ?
                    <span style={{ color: item.color }}
                        key={item.key}>
                        {item.notification}
                    </span> :
                    null
            ))}
        </div>
    );
}

export default forwardRef(Notificator);