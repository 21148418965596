import React, { useState, useEffect } from "react";

function GlobalError(props) {
    const [errors, setErrors] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const pushError = (message) => {
        const list = [...errors];
        list.push({
            lifetime: 3,
            message: message
        });

        setErrors(list);
    }

    const updateErrorsLifetime = () => {
        if (!refresh) return;
        setRefresh(false)
        const prev = errors
        if (prev.length === 0)
            return prev;

        const list = [...errors];

        let allTimeout = true;
        for (var error of list) {
            error.lifetime--;
            if (error.lifetime > 0) {
                allTimeout = false;
            }
        }

        if (allTimeout) {
            list.length = 0;
            if (props.onComplete)
                props.onComplete()
        }

        setErrors(list)
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setRefresh(true)
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        const error = props.error;
        if (error) {
            pushError(error)
        }

    }, [props.error]);

    useEffect(() => {
        updateErrorsLifetime();
    }, [refresh]);



    if (errors.length === 0) {
        return null;
    }

    return (
        <div style={{ display: "flex", backgroundColor: "red", flexDirection: "column", padding: "5px", borderRadius: "4px" }}>
            {
                errors.map((item, index) => (
                    item.lifetime > 0 ?
                        <span style={{ color: "white", fontSize: "16px" }} key={index}>{item.message}</span> :
                        null
                ))
            }
        </div>
    );
}

export default GlobalError;